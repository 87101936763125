import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import {
  IApiResponseModel,
  IApiRequestModel,
  IApiRequestResponseModel,
} from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ToastComponent } from "../../component/toast/toast.component";

@Component({
  selector: "app-request-reset-password",
  templateUrl: "./request-reset-password.component.html",
  styleUrls: ["./request-reset-password.component.css"],
})
export class RequestResetPasswordComponent implements OnInit {
  form: FormGroup;
  requestLoading: boolean = false;
  requestSuccess: boolean = false;
  requestFailed: boolean = false;
  submitted: boolean = false;
  errorMessage: string = "";
  redirectUrlString: any;

  mailformat: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  successMessage: any = "";

  constructor(
    private identityService: IdentityService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required, Validators.email])],
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.requestFailed = false;
    // this.requestLoading = true;
    this.requestSuccess = false;
    this.submitted = false;
    this.errorMessage = "";

    // stop here if form is invalid
    if (this.form.invalid) {
      if (!this.form.value.email.match(this.mailformat)) {
        this.requestFailed = true;
        this.errorMessage = "Please enter a valid email";
      }
      return;
    }

    const data = {
      ...this.form.value,
      redirectUrl: window.location.origin + "/reset-password",
    };

    this.sendRequest(data);
  }

  sendRequest = (data) => {
    this.requestLoading = true;
    this.identityService.requestPasswordReset(data).subscribe({
      next: (v) => {
        this.requestLoading = false;
        this.requestSuccess = true;
        this.successMessage =
          "Password reset link has been sent to" + " " + this.form.value.email;
        this.errorMessage = "";
      },

      error: (error) => {
        this.requestLoading = false;
        this.requestSuccess = false;
        this.requestFailed = true;
        this.errorMessage = error.message;
        this.successMessage = "";
      },
    });
  };
}
