import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { IApiResponseModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";

@Component({
  selector: "app-device-logs",
  templateUrl: "./device-logs.component.html",
  styleUrls: ["./device-logs.component.css"],
})
export class DeviceLogsComponent implements OnInit {
  searchWord: any;
  sn: any = String;
  User: any = {};
  userid: any;
  config: any;

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private avRout: ActivatedRoute,
    private Router: Router
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };

    this.avRout.queryParams.subscribe((params) => {
      this.config.currentPage = params["page"] ? params["page"] : 1;
    });
  }

  DeviceLogs: any = [];
  dtOptions: any = {};
  loading: boolean = false;
  success: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();

  pageChange(newPage: number) {
    const snNum = window.location.pathname.split("/")[2];
    const id = window.location.pathname.split("/")[3];
    this.Router.navigate([`/devicelog-by-serialn/${snNum}/${id}`], {
      queryParams: { page: newPage, size: 10 },
    });
  }

  ngOnInit(): void {
    const paramid = "sn";
    const paramuserid = "userid";
    if (this.avRout.snapshot.params[paramid]) {
      this.userid = this.avRout.snapshot.params[paramuserid];
      this.sn = this.avRout.snapshot.params[paramid];

      this.getUser(this.userid);
      this.getAllDevices(this.sn);
      this.dtOptions = {
        pagingType: "full_numbers",
        pageLength: 10,
        // Declare the use of the extension in the dom parameter
        dom: "Bfrtip",
        initComplete: function (settings, json) {
          $(".buttons").addClass("btn-success");
        },
        // Configure the buttons
        buttons: ["copy", "print", "excel"],
      };
    }
  }

  handleBackNav = () => {
    history.back();
  };

  getUser = (id) => {
    this.loading = true;
    this.managementRepo.GetUserbyid(id).subscribe({
      next: (v) => {
        this.User = v["data"];

        this.loading = false;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
      },
    });
  };

  viewdevice(sn) {}
  getAllDevices = (sn) => {
    this.loading = true;
    this.managementRepo.GetDeviceLogs(sn).subscribe({
      next: (v) => {
        this.DeviceLogs = v["data"];

        this.dtTrigger.next();
        this.loading = false;
        this.success = true;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
        this.success = false;
      },
    });
  };

  convertToDate(dateString) {
    const date = new Date(dateString);
    return date.toDateString();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
