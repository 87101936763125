import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChartDataSets, ChartOptions } from "chart.js";
import { Color, Label } from "ng2-charts";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";

@Component({
  selector: "app-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.css"],
})
export class LineChartComponent implements OnInit {
  loading: boolean;
  Devicecolours: any;
  dtTrigger: any;
  white: any = 0;
  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private avRout: ActivatedRoute
  ) {
    // this.getUserLocation();
  }

  lineChartData: ChartDataSets[] = [
    { data: [this.white], label: "Crude oil prices" },
  ];
  lineChartLabels: Label[] = [
    "1January",
    "February",
    "March",
    "April",
    "May",
    "June",
  ];
  lineChartOptions = {
    responsive: true,
  };
  lineChartColors: Color[] = [
    {
      borderColor: "black",
      backgroundColor: "rgba(255,255,0,0.28)",
    },
  ];
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = "line";

  ngOnInit(): void {}

  getDeviceColour = () => {
    this.loading = true;
    this.managementRepo.GetDeviceColour().subscribe({
      next: (v) => {
        this.Devicecolours = v["data"];
        this.dtTrigger.next();
        this.loading = false;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
      },
    });
  };
}
