import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Label } from "ng2-charts";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";

@Component({
  selector: "app-users-by-number-of-gt",
  templateUrl: "./users-by-number-of-gt.component.html",
  styleUrls: ["./users-by-number-of-gt.component.css"],
})
export class UsersByNumberOfGtComponent implements OnInit {
  loading: boolean;
  usersWith0GT: any;
  usersWith2GT: any;
  usersWith3GT: any;
  usersWith4PlusGT: any;
  usersWith1GT: any;

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private avRout: ActivatedRoute
  ) {
    this.getDeviceCountry();
  }
  ngOnInit(): void {}

  barChartOptions: ChartOptions = {
    responsive: true,
    onClick: function (e) {
      var element = this.getElementAtEvent(e);

      if (element.length && element[0]._view.label === "4 and More Gametimer") {
        const text1 = element[0]._view.label.split(" ")[0];
        const text2 = element[0]._view.label.split(" ")[1];
        const text3 = element[0]._view.label.split(" ")[2];
        const text4 = element[0]._view.label.split(" ")[3];
        window.location.assign(
          window.location.origin +
            `/users-by-gt-data/${text1}-${text2}-${text3}-${text4}`
        );
      } else {
        const text1 = element[0]._view.label.split(" ")[0];
        const text2 = element[0]._view.label.split(" ")[1];
        window.location.assign(
          window.location.origin + `/users-by-gt-data/${text1}-${text2}`
        );
      }
    },
  };

  public chartColors: any[] = [
    {
      backgroundColor: ["green", "indigo", "violet", "grey", "#E64F22"],
    },
  ];

  barChartLabels: Label[] = [
    "0 Gametimer ",
    "1 Gametimer",
    "2 Gametimer",
    "3 Gametimer ",
    "4 and More Gametimer ",
  ];
  barChartType: ChartType = "bar";
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: ChartDataSets[] = [{ label: "Users by Number of Gametimer" }];

  getDeviceCountry = () => {
    this.loading = true;
    this.managementRepo.getUsersByNumOfGT().subscribe({
      next: (v) => {
        this.usersWith0GT = v["usersWith0GT"].length;
        this.usersWith1GT = v["usersWith1GT"].length;
        this.usersWith2GT = v["usersWith2GT"].length;
        this.usersWith3GT = v["usersWith3GT"].length;
        this.usersWith4PlusGT = v["usersWith4PlusGT"].length;
        this.loading = false;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
      },
    });
  };
}
