import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { IApiResponseModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";

@Component({
  selector: "app-log-action",
  templateUrl: "./log-action.component.html",
  styleUrls: ["./log-action.component.css"],
})
export class LogActionComponent implements OnInit {
  sn: any;
  User: any;
  id: any;

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private avRout: ActivatedRoute
  ) {
    // this.getUserLocation();
  }

  DeviceLogs: any;
  action: IApiResponseModel[];
  dtOptions: any = {};
  loading: boolean = false;
  success: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();
  ngOnInit(): void {
    const paramid = "id";
    if (this.avRout.snapshot.params[paramid]) {
      this.id = this.avRout.snapshot.params[paramid];

      // this.getUser(this.userid);
      this.getlog(this.id);
      this.dtOptions = {
        pagingType: "full_numbers",
        pageLength: 12,
        // Declare the use of the extension in the dom parameter
        dom: "Bfrtip",
        initComplete: function (settings, json) {
          $(".buttons").addClass("btn-success");
        },
        // Configure the buttons
        buttons: ["copy", "print", "excel"],
      };
    }
  }

  handleBackNav = () => {
    history.back();
  };

  getlog = (id) => {
    this.loading = true;
    this.managementRepo.GetLog(id).subscribe({
      next: (v) => {
        this.DeviceLogs = v["data"];
        this.action = v["data"].actions;
        this.dtTrigger.next();
        this.loading = false;
        this.success = true;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
        this.success = false;
      },
    });
  };

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
