<div class="container mt-2">
  <div class="row justify-content-center">
    <div
      class="col-lg-6 col-md-6 col-sm-12 col-12 m-2"
      style="background-color: #ffffff; border-radius: 10px"
    >
      <div class="chart-wrapper">
        <canvas
          baseChart
          [datasets]="barChartData"
          Dashboard
          [data]="[
            Data.Users_count?.count,
            Data.Device_count?.count,
            Data.ActiveDevice?.count,
            Data.InActiveDevice?.count,
            Data.Users_without_GT?.count
          ]"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [plugins]="barChartPlugins"
          [legend]="barChartLegend"
          [colors]="chartColors"
          [chartType]="barChartType"
        >
        </canvas>
      </div>
    </div>
    <div
      class="col-lg-5 col-md-5 col-sm-12 col-12 m-2 p-4"
      style="background-color: #ffffff; border-radius: 10px"
    >
      <span class="mycolor">
        <i class="fa fa-tablet fa-2x"> </i> Total Registerd Account
        <span class="h5"> [{{ Data ? Data.Users_count?.count : 0 }}] </span>
      </span>
      <p></p>
      <span class="">
        <i class="fa fa-tablet fa-2x"> </i> Total Registered Device
        <span class="h5"> [{{ Data ? Data.Device_count?.count : 0 }} ]</span>
      </span>
      <p></p>

      <span class="text-success">
        <i class="fa fa-tablet fa-2x"> </i> Total Active Device
        <span class="h5"> [{{ Data ? Data.ActiveDevice?.count : 0 }}] </span>
      </span>
      <p></p>

      <span class="text-secondary">
        <i class="fa fa-tablet fa-2x"> </i> Total Inactive Device
        <span class="h5"> [ {{ Data ? Data.InActiveDevice?.count : 0 }} ]</span>
      </span>
      <p></p>

      <span class="text-primary">
        <i class="fa fa-tablet fa-2x"> </i> Total Users without Gametimer
        <span class="h5"> [{{ Data ? Data.Users_without_GT?.count : 0 }}] </span>
      </span>
      <p></p>
    </div>
  </div>

  <div class="row justify-content-center">
    <div
      class="col-md-6 col-sm-12 col-12 m-2"
      style="background-color: #ffffff; border-radius: 10px"
    >
      <app-bar-chart></app-bar-chart>
    </div>
    <div
      class="col-md-5 col-sm-12 col-12 m-2"
      style="background-color: #ffffff; border-radius: 10px"
    >
      <app-doughnut-chart></app-doughnut-chart>
    </div>
  </div>

  <div class="row justify-content-center">
    <div
      class="col-md-6 col-sm-12 col-12 m-2"
      style="background-color: #ffffff; border-radius: 10px"
    >
      <app-users-by-number-of-gt></app-users-by-number-of-gt>
    </div>
    <div
      class="col-md-5 col-sm-12 col-12 m-2"
      style="background-color: #ffffff; border-radius: 10px"
    >
      <app-bubble-chart></app-bubble-chart>
    </div>
  </div>

  <!-- <div class="row mt-2 justify-content-center">
    <div
      class="col-md-6 col-sm-12 col-12 m-2"
      style="background-color: #ffffff; border-radius: 10px"
    >
      <app-bubble-chart></app-bubble-chart>
    </div>
    <div
      class="col-md-6 col-sm-12 col-12 m-2"
      style="background-color: #ffffff; border-radius: 10px"
    >
      <app-users-by-number-of-gt></app-users-by-number-of-gt>
    </div>
  </div> -->
</div>

<app-spinner *ngIf="loading" [loading]="true"></app-spinner>
