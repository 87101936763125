<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-2">
      <a (click)="handleBackNav()" class="btn btn-secondary ml-3"> Back </a>
    </div>
    <div class="col-md-8">
      <h3 class="page-titl text-center">All Admin Users</h3>
    </div>
  </div>
  <div class="row align-items-center mt-3">
    <div class="col-md-6">
      <button
        *ngIf="Users?.length !== 0"
        (click)="onPrintClick()"
        class="download-btn"
      >
        Print Data
      </button>
      <button
        *ngIf="Users?.length !== 0"
        (click)="onPrintClick()"
        class="download-btn"
      >
        Excel Export
      </button>
      <button
        *ngIf="Users?.length !== 0"
        (click)="onPrintClick()"
        class="download-btn"
      >
        Copy Data
      </button>
    </div>
    <div class="col-md-6" *ngIf="loading === false">
      <input
        class="search-box"
        type="text"
        placeholder="Search"
        name="searchWord"
        [(ngModel)]="searchWord"
      />
    </div>
  </div>

  <div class="table-hover table-responsive p-3" id="print-section">
    <table class="row-border hover table " id="tableData">
      <thead>
        <tr class="print-headers">
          <th (click)="sort('fullname')">
            Full Name <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('email')">Email <i class="fa fa-sort"></i></th>
          <th (click)="sort('phone')">Phone <i class="fa fa-sort"></i></th>
          <th (click)="sort('role_id')">Role <i class="fa fa-sort"></i></th>
          <th (click)="sort('createdAt')">
            Date Registered <i class="fa fa-sort"></i>
          </th>
          <th>Action</th>
          <!-- <th></th> -->
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let user of Users
              | orderBy: orderHeader:isDescOrder:caseInsensitive
              | filter: searchWord
              | paginate: config
          "
        >
          <td>{{ user?.fullname }}</td>
          <td>{{ user?.email }}</td>
          <td>{{ user?.phone ? user?.phone : "nill" }}</td>
          <td>
            {{
              user?.role_id === 1
                ? "SuperAdmin"
                : user?.role_id === 2
                ? "Admin"
                : "User"
            }}
          </td>
          <td>{{ user?.createdAt | date: "y-MM-dd" }}</td>
          <!-- <td>
            <a [routerLink]="['/device/', user?.id]" class="btn btn-primary">
              View Account
            </a>
          </td> -->
          <td>
            <button
              *ngIf="loggedInUser?.role_id === 1"
              class="btn btn-danger"
              (click)="deleteUser(user.id)"
            >
              Delete User
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="Users?.length > 10">
  <pagination-controls
    (pageChange)="pageChange($event)"
    class="my-pagination"
  ></pagination-controls>
</div>
<app-spinner *ngIf="loading" [loading]="true"></app-spinner>
