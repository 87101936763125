import { BrowserModule } from "@angular/platform-browser";

import { NgModule } from "@angular/core";

import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./component/login/login.component";
import { UserGaurd } from "./Helper/UserGaurd";
import { RequestResetPasswordComponent } from "./views/request-reset-password/request-reset-password.component";
import { ResetPasswordComponent } from "./views/reset-password/reset-password.component";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("src/app/modules/layout/layout.module").then(
        (mod) => mod.LayoutModule
      ),
    canActivate: [UserGaurd],
  },
  { path: "login", component: LoginComponent },
  { path: "request-password-reset", component: RequestResetPasswordComponent },
  {
    path: "reset-password/:userId/:resetString",
    component: ResetPasswordComponent,
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [BrowserModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
