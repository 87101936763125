import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Label } from "ng2-charts";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";

@Component({
  selector: "app-bubble-chart",
  templateUrl: "./bubble-chart.component.html",
  styleUrls: ["./bubble-chart.component.css"],
})
export class BubbleChartComponent implements OnInit {
  loading: boolean;
  Devicecolours: any;
  two_five: any;
  five_eight: any;
  eight_twleve: any;
  twleve_: any;
  fifteen_: any;

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private avRout: ActivatedRoute
  ) {
    this.getDeviceCountry();
  }
  ngOnInit(): void {}

  barChartOptions: ChartOptions = {
    responsive: true,
    onClick: function (e) {
      var element = this.getElementAtEvent(e);
      // console.log("e", element[0]._view.label.split(" "));
      if (element.length) {
        const text1 = element[0]._view.label.split(" ")[0];
        const text3 = element[0]._view.label.split(" ")[2];

        window.location.assign(
          window.location.origin + `/users-age-data/${text1}-${text3}`
        );
      }
    },
  };

  public chartColors: any[] = [
    {
      backgroundColor: ["#E64F22", "#000000", "green", "grey", "orange"],
    },
  ];
  barChartLabels: Label[] = [
    "2 - 5 ",
    "5 - 8",
    "8 - 12 ",
    "12 - 15 ",
    "15 - 20 ",
  ];
  barChartType: ChartType = "bar";
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: ChartDataSets[] = [{ label: "Age range" }];
  getDeviceCountry = () => {
    this.loading = true;
    this.managementRepo.GetAge().subscribe({
      next: (v) => {
        this.Devicecolours = v["data"];
        this.two_five = v["data"].result2_5.count;
        this.fifteen_ = v["data"].result15_20.count;
        this.five_eight = v["data"].result5_8.count;
        this.eight_twleve = v["data"].result8_12.count;
        this.twleve_ = v["data"].result12_15.count;
        this.loading = false;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
      },
    });
  };
}
