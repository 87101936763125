<div class="container-fluid pt-3">
  <a (click)="handleBackNav()" class="btn btn-secondary ml-3 mb-2"> Back </a>

  <h3 class="text-center">
    <strong>{{ User.name ? User.name : "" }}</strong> Device Logs With Serial
    number - <br />
    {{ sn }}
  </h3>
  <div *ngIf="!success && !loading" class="card">
    <h5 class="text-danger p-5">
      No Log Record Found for device with Serial Number - {{ sn }}
    </h5>
  </div>
  <div class="table table-responsive d-flex justify-content-center p-3">
    <table
      datatable
      [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger"
      class="row-border hover"
    >
      <thead *ngIf="success">
        <tr>
          <th>Serial Number</th>
          <th>Start Time</th>
          <th>End Time</th>

          <th>Date Registerd</th>

          <th>Action</th>
        </tr>
      </thead>
      <tbody *ngIf="success">
        <tr *ngFor="let user of DeviceLogs | paginate: config">
          <td>{{ user.serialNumber }}</td>
          <td>{{ user.timeStamp | date: "MMM d, y, h:mm:ss a" }}</td>
          <td>{{ user.endTime | date: "MMM d, y, h:mm:ss a" }}</td>

          <td>{{ convertToDate(user.createdAt) }}</td>
          <td>
            <!-- <a [routerLink]="['/log-action/', user.id]" class="btn btn-primary">
              View Actions</a
            > -->

            <div class="dropdown show">
              <a
                class="btn btn-secondary dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                More
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <!-- <a
                  [routerLink]="['/log-action/', user.id]"
                  class="dropdown-item text-primary"
                >
                  View Actions</a
                > -->
                <a
                  [routerLink]="['/log-action/', user.id]"
                  class="dropdown-item text-primary"
                >
                  View Actions</a
                >
                <a
                  [routerLink]="['/device/', userid]"
                  class="dropdown-item text-warning"
                >
                  View Account
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="DeviceLogs.length > 12">
    <pagination-controls
      (pageChange)="pageChange($event)"
      class="my-pagination"
    ></pagination-controls>
  </div>
</div>

<app-spinner *ngIf="loading" [loading]="true"></app-spinner>
