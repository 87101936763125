<div class="chart-wrapper">
  <canvas baseChart
  [datasets]="barChartData"
  [data]="[nl,be,uk,fr,us,others]"
  [labels]="barChartLabels"
  [options]="barChartOptions"
  [plugins]="barChartPlugins"
  [legend]="barChartLegend"
  [colors]="chartColors"
  [chartType]="barChartType">
</canvas>

<div class=" mt-2 mb-3">

  Netherland = {{ nl}}  ,  Belgium = {{ be }}, United Kingdom = {{ uk }} , France =  {{ fr }}, United State = {{ us }}, Others = {{ others }}

</div>

</div>
