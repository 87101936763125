<div class="chart-wrapper">
  <canvas
    baseChart
    [datasets]="barChartData"
    [data]="[
      usersWith0GT,
      usersWith1GT,
      usersWith2GT,
      usersWith3GT,
      usersWith4PlusGT
    ]"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [colors]="chartColors"
    [chartType]="barChartType"
  >
  </canvas>

  <div class="mt-2 mb-3">
    Users with 0 GT = {{ usersWith0GT }}, Users with 1 GT = {{ usersWith1GT }},
    Users with 2 GT = {{ usersWith2GT }}, Users with 3 GT = {{ usersWith3GT }},
    Users with 4 and more GT = {{ usersWith4PlusGT }}
  </div>
</div>
