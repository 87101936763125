import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { IApiResponseModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";

@Component({
  selector: "app-devicelog-byserialnumber",
  templateUrl: "./devicelog-byserialnumber.component.html",
  styleUrls: ["./devicelog-byserialnumber.component.css"],
})
export class DevicelogByserialnumberComponent implements OnInit {
  searchWord: any;
  sn: any;
  User: any;
  userid: any;
  config: any;

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private route: ActivatedRoute,
    private Router: Router
  ) {
    // this.getUserLocation();
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };

    this.route.queryParams.subscribe(
      (params) =>
        (this.config.currentPage = params["page"] ? params["page"] : 1)
    );
  }

  DeviceLogs: IApiResponseModel[];
  dtOptions: any = {};
  loading: boolean = false;
  success: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();


  pageChange(newPage: number) {
    const id = window.location.pathname.split("/")[1];
    this.Router.navigate([`/log-action/${id}`], {
      queryParams: { page: newPage, size: 10 },
    });
  }

  ngOnInit(): void {
    const paramid = "sn";

    if (this.route.snapshot.params[paramid]) {
      this.sn = this.route.snapshot.params[paramid];

      // this.getUser(this.userid);
      this.getAllDevices(this.sn);
      this.dtOptions = {
        pagingType: "full_numbers",
        pageLength: 10,
        // Declare the use of the extension in the dom parameter
        dom: "Bfrtip",
        initComplete: function (settings, json) {
          $(".buttons").addClass("btn-success");
        },
        // Configure the buttons
        buttons: ["copy", "print", "excel"],
      };
    }
  }

  handleBackNav = () => {
    history.back();
  };

  getAllDevices = (sn) => {
    this.loading = true;
    this.managementRepo.GetDeviceLogs(sn).subscribe({
      next: (v) => {
        this.DeviceLogs = v["data"];
 
        this.dtTrigger.next();
        this.loading = false;
        this.success = true;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
        this.success = false;
      },
    });
  };

  convertToDate(dateString) {
    const date = new Date(dateString);
    return date.toDateString();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
