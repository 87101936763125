import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChartType } from "chart.js";
import { MultiDataSet, Label } from "ng2-charts";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
@Component({
  selector: "app-doughnut-chart",
  templateUrl: "./doughnut-chart.component.html",
  styleUrls: ["./doughnut-chart.component.css"],
})
export class DoughnutChartComponent {
  loading: boolean;
  Devicecolours: [];
  dtTrigger: any;

  white: any = 0;
  black: any = 0;
  army: any = 0;
  blue: any = 0;

  public chartColors: any[] = [
    {
      backgroundColor: ["#FFFFFF", "#000000", "silver", "blue"],
    },
  ];

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private avRout: ActivatedRoute
  ) {
    this.getDeviceColour();
  }
  ngOnInit(): void {
    this.getDeviceColour();
  }

  doughnutChartLabels: Label[] = ["WHITE", "BLACK", "ARMY", "BLUE"];

  doughnutChartType: ChartType = "doughnut";

  getDeviceColour = () => {
    this.loading = true;
    this.managementRepo.GetDeviceColour().subscribe({
      next: (v) => {
        this.Devicecolours = v["data"];
        this.white = v["data"].white.count;
        this.black = v["data"].black.count;
        this.blue = v["data"].blue.count;
        this.army = v["data"].army.count;
        this.loading = false;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
      },
    });
  };
}
