import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { IApiResponseModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  form: FormGroup;
  registerloading = false;
  registersuccess = false;
  errormessage = "";
  registerfailed = false;
  duplicateusername = false;
  duplicateuseremail = false;
  submitted = false;
  User: any;
  role: string;
  Roles: any[];

  SuccessMessage: string | null;
  formBuilder: any;
  constructor(private AuthService: IdentityService) {}
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
  }

  ngOnInit(): void {
    this.GetLoginuser();
    this.form = this.formBuilder.group({
      password: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      npassword: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });
  }

  get f() {
    return this.form.controls;
  }

  GetLoginuser() {
    this.AuthService.GetUser().subscribe({
      next: (v) => {
        this.User = v.data;
        this.role = v.rolename;
        // this.form.controls.email.setValue('oko');
      },
      error: (e) => {},
      complete: () => {},
    });
  }
}
