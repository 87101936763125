<!-- <td class="border-0 m-0 py-0">
  {{ UserDetails?.email ? UserDetails?.email : "nil" }}
  {{
    UserDetails === "null"
      ? "loading.."
      : UserDetails?.email
      ? UserDetails?.email
      : "nil"
  }}
</td> -->
<font> {{ UserDetails ? UserDetails : "loading..." }} </font>

<!-- <td>devise-user-details works!</td> -->
