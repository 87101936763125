import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { catchError, switchMap, filter, take } from "rxjs/operators";
import { IdentityService } from "./../Services/identity.service";
import { Router } from "@angular/router";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private IsRefreshing = false;
  private RefreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private authenticationService: IdentityService,
    private route: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authenticationService.Isuserlogin()) {
      request = this.AddToken(request, this.authenticationService.getToken());
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // auto logout if 401 response returned from api
          localStorage.clear();
          this.route.navigate(["/login"]);
          // return   this.Handle401Error(request, next);
        }

        //  const error = err.error.message || err.statusText;
        const errormgs = {
          ErrorCode: err.status,
          Message: err.message,
          Response: err.error,
        };
        //  console.log(errormgs);
        return throwError(errormgs.Response);
      })
    );
  }

  AddToken(request: HttpRequest<any>, token: any) {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }
}
