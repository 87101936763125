<app-spinner *ngIf="loading" [loading]="true"></app-spinner>

<div class="container">
  <div class="row row-div">
    <div class="col card-col">
      <div class="card border-0">
        <h4 class="card-header csutom-color border-0 text-center">
          <strong class="text-center">Reset Password</strong>
        </h4>
        <div class="card-body">
          <div
            *ngIf="requestSuccess"
            class="alert alert-success fade show text-center"
            role="alert"
          >
            {{ successMessage }}
          </div>
          <div
            *ngIf="requestFailed"
            class="alert alert-danger fade show text-center"
            role="alert"
          >
            * {{ errorMessage }}
          </div>

          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <div class="input-group mb-3">
                <input
                  type="password"
                  formControlName="newPassword"
                  class="form-control input"
                  placeholder="New Password"
                  [ngClass]="{
                    'is-invalid': submitted && f.newPassword.errors
                  }"
                />
                <div class="input-group-text">
                  <i class="fas fa-lock"></i>
                </div>
              </div>

              <div class="input-group">
                <input
                  type="password"
                  formControlName="confirmPassword"
                  class="form-control input"
                  placeholder="Confirm Password"
                  [ngClass]="{
                    'is-invalid': submitted && f.confirmPassword.errors
                  }"
                />
                <div class="input-group-text">
                  <i class="fas fa-lock"></i>
                </div>
              </div>

              <div
                *ngIf="submitted && f.newPassword.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.newPassword.errors.required">
                  Password is required
                </div>
              </div>
            </div>

            <div class="form-group">
              <button
                [disabled]="requestLoading"
                class="btn btn-block loginbtn input"
              >
                <span
                  *ngIf="requestLoading"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                Reset Password
              </button>
              <p class="text-center mt-3">
                <a [routerLink]="['/login']" class="text-center mt-4">Login</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
