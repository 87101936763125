<h1 *ngIf="DeviceLogs.length === 0">Loading...</h1>
<div class="row">
  <div class="col-md-2">
    <a (click)="handleBackNav()" class="btn btn-secondary ml-3 my-3"> Back </a>
  </div>
  <div class="col-md-8">
    <h3 *ngIf="DeviceLogs.length > 0" class="text-center mt-2">{{ pageTitle }}</h3>
  </div>
</div>

<div ngxCopyPaste #cp="copy">
  <div>
    <button printSectionId="print-section" class="download-btn" ngxPrint>
      Print
    </button>
    <button (click)="exportExcel()" class="download-btn">Excel</button>
    <button (click)="cp.copy()" class="download-btn">Copy</button>
  </div>

  <div
    *ngIf="DeviceLogs.length > 0"
    class="table-hover table-responsive p-3"
    id="print-section"
  >
    <table class="row-border hover table" id="excel-table">
        <thead>
            <tr class="print-headers">
              <th (click)="sort('serialNumber')">Serial Number <i class="fa fa-sort"></i></th>
              <th (click)="sort('timeStamp')">Start Time <i class="fa fa-sort"></i></th>
              <th (click)="sort('endTime')">End Time <i class="fa fa-sort"></i></th>
    
              <th (click)="sort('createdAt')">Date Registerd <i class="fa fa-sort"></i></th>
    
            </tr>
          </thead>
      
          <tbody>
            <tr
              *ngFor="
                let user of DeviceLogs
              "
            >
              <td>{{ user.serialNumber }}</td>
              <td>{{ user.timeStamp | date: "MMM d, y, h:mm:ss a" }}</td>
              <td>{{ user.endTime | date: "MMM d, y, h:mm:ss a" }}</td>
    
              <td>{{ convertToDate(user.createdAt) }}</td>

            </tr>
          </tbody>
    </table>
    <div id="content-loaded"></div>
  </div>
</div>
