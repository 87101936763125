import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { IApiResponseModel, ILoginModel } from "src/app/interface/interface";
import { LoginMode } from "../model/models";

@Injectable({
  providedIn: "root",
})
export class ManagementService {
  constructor(private httpClient: HttpClient) {}

  downloadExcel(data) {
    return this.httpClient.post(environment.BaseUrl + "management/excel", data);
  }

  GetAllRegisteredUser(): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getallusers"
    );
  }

  getUsersByNumOfGT(): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getUsersByNumOfGT"
    );
  }

  VerifyUser(id): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/verifyUser/" + id
    );
  }

  GetAllDevice(): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getalldevices"
    );
  }

  GetLog(id): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getlog/" + id
    );
  }

  GetAllDeviceLogs(): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getalldevicelogs"
    );
  }

  GetAge(): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getage"
    );
  }

  GetDeviceColour(): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getdevicecolour"
    );
  }

  GetDeviceCountry(): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getcountry"
    );
  }

  GetDevicestatus(): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getdevicestatus"
    );
  }
  getdevicebyuser(id): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getdevicebyuser/" + id
    );
  }

  getdevicesuser(): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getdevices-user"
    );
  }

  GetDeviceLogs(sn): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getdevicelog/" + sn
    );
  }

  GetUserbyid(id: any): Observable<IApiResponseModel> {
    return this.httpClient.get<IApiResponseModel>(
      environment.BaseUrl + "management/getuserbyid/" + id
    );
  }

  deleteUserById(id: any): Observable<IApiResponseModel> {
    return this.httpClient.delete<IApiResponseModel>(
      environment.BaseUrl + "management/deleteUserById/" + id
    );
  }

  deleteDeviceById(id: any): Observable<IApiResponseModel> {
    return this.httpClient.delete<IApiResponseModel>(
      environment.BaseUrl + "management/deleteDeviceById/" + id
    );
  }

  GetUserbyage(age: any): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getuserbyage/" + age
    );
  }

  GetUserbycity(city: any): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getuserbycity/" + city
    );
  }

  GetUserbyemial(email: any): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getuserbyage/" + email
    );
  }

  GetUserbyname(name: any): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getuserbyname/" + name
    );
  }

  GetUserbydate(date: any): Observable<IApiResponseModel[]> {
    return this.httpClient.get<IApiResponseModel[]>(
      environment.BaseUrl + "management/getuserbydateregistered/" + date
    );
  }

  CountAllUser(): Observable<IApiResponseModel> {
    return this.httpClient.get<IApiResponseModel>(
      environment.BaseUrl + "management/countallusers"
    );
  }

  CountActiveUser(): Observable<IApiResponseModel> {
    return this.httpClient.get<IApiResponseModel>(
      environment.BaseUrl + "management/countactiveusers"
    );
  }
}
