import { Component, OnInit } from '@angular/core';
// Import from library
import {
  AppearanceAnimation,
  DialogLayoutDisplay,
  ToastNotificationInitializer
} from '@costlydeveloper/ngx-awesome-popup';
import { bounceIn } from '@costlydeveloper/ngx-awesome-popup/ngx-awesome-popup/core/animations/keyframes';



@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }



  errortoastNotification(errormgs: string) {
    const newToastNotification = new ToastNotificationInitializer();
    newToastNotification.setTitle('Error !');
    newToastNotification.setMessage(errormgs);

    // Choose layout color type
    newToastNotification.setConfig({
        LayoutType: DialogLayoutDisplay.DANGER, // SUCCESS | INFO | NONE | DANGER | WARNING
        AnimationIn: AppearanceAnimation.BOUNCE_IN,

    });

    // Simply open the toast
    newToastNotification.openToastNotification$();
}

successtoastNotification(mgs: string) {
  const newToastNotification = new ToastNotificationInitializer();
  newToastNotification.setTitle('Successful');
  newToastNotification.setMessage(mgs);

  // Choose layout color type
  newToastNotification.setConfig({
      LayoutType: DialogLayoutDisplay.SUCCESS //| INFO | NONE | DANGER | WARNING
  });

  // Simply open the toast
  newToastNotification.openToastNotification$();
}


}
