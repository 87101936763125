import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { IdentityService } from "src/app/Services/identity.service";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { animation } from "@angular/animations";
import { Observable } from "rxjs";

@Component({
  selector: "app-layout-view",
  templateUrl: "./layout-view.component.html",
  styleUrls: ["./layout-view.component.css"],
})
export class LayoutViewComponent implements OnInit {
  active = 1;
  UserName: string = "";
  UserPanel = false;
  UserLogOut = true;
  loading: boolean = false;
  isopen = true;
  isclose = false;
  user: any = {};
  constructor(
    private modalService: NgbModal,
    private config: NgbModalConfig,
    private AuthService: IdentityService,
    private Routr: Router
  ) {
    // customize default values of modals used by this component tree
    config.backdrop = "static";
    config.keyboard = false;
    animation: true;
  }

  ngOnInit(): void {
    this.GetLoginuser();
    document.getElementById("main").style.marginLeft = "300px";
  }
  /* Set the width of the side navigation to 250px */
  openNav() {
    document.getElementById("mySidenav").style.width = "300px";
    document.getElementById("main").style.marginLeft = "300px";

    this.isopen = true;
    this.isclose = false;
  }

  open(content: any) {
    this.modalService.open(content);
  }
  close(content: any) {
    this.modalService.dismissAll();
    this.modalService.open(content);
  }

  /* Set the width of the side navigation to 0 */
  closeNav() {
    document.getElementById("mySidenav").style.width = "0px";
    document.getElementById("main").style.marginLeft = "0";

    this.isopen = false;
    this.isclose = true;
  }

  /* Toggle between adding and removing the "responsive" class to topnav when the user clicks on the icon */
  myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  GetLoginuser() {
    this.AuthService.GetUser().subscribe({
      next: (v) => {
        this.user = v.data;
        this.UserName = v.data.fullname;
      },
      error: (e) => {},
      complete: () => {},
    });
  }

  LogOut() {
    if (confirm("Are you sure to Signout ")) {
      this.AuthService.LogOut().subscribe((data) => {
        localStorage.removeItem("token");
        localStorage.clear();
        this.Routr.navigate(["/login"]);
      });
    }
  }
}
