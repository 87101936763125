<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-2">
      <a (click)="handleBackNav()" class="btn btn-secondary ml-3"> Back </a>
    </div>
    <div class="col-md-8">
      <h3 class="page-titl text-center">All Registered Devices</h3>
    </div>
  </div>
  <div class="row align-items-center mt-3">
    <div class="col-md-6">
      <button *ngIf="Users?.length !== 0" (click)="onPrintClick()" class="download-btn">
        Copy Data
      </button>
      <button *ngIf="Users?.length !== 0" (click)="onPrintClick()" class="download-btn">
        Excel Export
      </button>
      <button *ngIf="Users?.length !== 0" (click)="onPrintClick()" class="download-btn">
        Print Data
      </button>
    </div>
    <div class="col-md-6" *ngIf="loading === false">
      <input class="search-box" type="text" placeholder="Search" name="searchWord" [(ngModel)]="searchWord" />
    </div>
  </div>

  <div class="table-hover table-responsive p-3" id="print-section">
    <table class="row-border hover table" id="tableData">
      <thead>
        <tr class="print-headers">
          <th (click)="sort('serialNumber')">
            Serial Number<i class="fa fa-sort"></i>
          </th>

          <th (click)="sort('holidayMode')">
            Holiday Mode <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('flightMode')">
            Flight Mode <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('version')">Version <i class="fa fa-sort"></i></th>
          <th (click)="sort('userName')">
            User Name <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('userId')">
            User Email <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('userAge')">User Age <i class="fa fa-sort"></i></th>
          <th (click)="sort('createdAt')">
            Date Registerd <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('updatedAt')">
            Update At <i class="fa fa-sort"></i>
          </th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr
          *ngFor="let user of Devices | filter: searchWord | paginate: config"
        > -->
        <tr *ngFor="
            let user of Devices
              | orderBy: orderHeader:isDescOrder:caseInsensitive
              | filter: searchWord
              | paginate: config
          ">
          <td>{{ user.serialNumber }}</td>

          <td>{{ user.holidayMode }}</td>
          <td>{{ user.flightMode }}</td>
          <td>
            {{ user.version ? user.version : "Not Mentioned" }}
          </td>
          <td>{{ user.userName }}</td>
          <td>
            <app-devise-user-details [userId]="user?.userId">
            </app-devise-user-details>
          </td>
          <td>{{ user.userAge | dateAgo }}</td>
          <td>{{ convertToDate(user.createdAt) }}</td>
          <td>{{ convertToDate(user.updatedAt) }}</td>

          <td>
            <div class="dropdown show">
              <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                More
              </a>

              <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a type="button" class="dropdown-item text-primary" (click)="open(content, user)">View Details</a>
                <a [routerLink]="[
                    '/devicelog-by-serialn/',
                    user.serialNumber,
                    user.userId
                  ]" class="dropdown-item text-warning">
                  View Logs</a>
                <a [routerLink]="['/device/', user.userId]" class="dropdown-item text-secondary">
                  View Account
                </a>
                <a *ngIf="loggedInUser?.role_id !== 3" type="button" class="dropdown-item text-danger"
                  (click)="deleteDevice(user)">
                  Remove Device
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="Devices.length > 10">
      <pagination-controls (pageChange)="pageChange($event)" class="my-pagination"></pagination-controls>
    </div>
  </div>

  <ng-template #content let-modal>
    <h1 class="modal-title w-100 text-center" id="modal-basic-title">
      {{ userData.userName }}
    </h1>
    <div class="modal-body">
      <div class="row">
        <!-- <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label"
              >Salutation</label
            >
            <input
              type="email"
              class="form-control modal-form"
              id="exampleFormControlInput1"
              value="{{ userData.salutation ? userData.salutation : 'nil' }}"
              readonly
            />
          </div>
        </div> -->
        <div class="col">
          <div class="mb-4">
            <label for="exampleFormControlInput1" class="form-label">Name</label>
            <input type="text" class="form-control modal-form" id="exampleFormControlInput1" value="{{ userData.name }}"
              readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-4">
            <label for="exampleFormControlInput2" class="form-label">User Name</label>
            <input type="text" class="form-control modal-form" id="exampleFormControlInput2"
              value="{{ userData.userName }}" readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-4">
            <label for="exampleFormControlInput3" class="form-label">Email</label>
            <input type="email" class="form-control modal-form" id="exampleFormControlInput3"
              value="{{ userData.email }}" readonly />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput4" class="form-label">Country</label>
            <input type="text" class="form-control modal-form" id="exampleFormControlInput4"
              value="{{ userData.country }}" readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput5" class="form-label">City</label>
            <input type="text" class="form-control modal-form" id="exampleFormControlInput5" value="{{ userData.city }}"
              readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput6" class="form-label">Language</label>
            <input type="text" class="form-control modal-form" id="exampleFormControlInput6"
              value="{{ userData.language }}" readonly />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput7" class="form-label">Phone Number</label>
            <input type="text" class="form-control modal-form" id="exampleFormControlInput7"
              value="{{ userData.phone ? userData.phone : 'nil' }}" readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput8" class="form-label">Device Name</label>
            <input type="text" class="form-control modal-form" id="exampleFormControlInput8"
              value="{{ userData.deviceName }}" readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput9" class="form-label">Device Status</label>
            <input type="text" class="form-control modal-form" id="exampleFormControlInput9"
              value="{{ userData.deviceStatus }}" readonly />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Close
      </button>
    </div>
  </ng-template>
</div>

<app-spinner *ngIf="loading" [loading]="true"></app-spinner>