import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as XLSX from "xlsx";

@Component({
  selector: "app-print-device",
  templateUrl: "./print-device.component.html",
  styleUrls: ["./print-device.component.css"],
})
export class PrintDeviceComponent implements OnInit {
  Devices: any = [];
  filename = "ExcelSheet.xlsx";
  url: any = [];
  loading: boolean = false;
  success: boolean = false;
  dataReady: boolean = false;
  userDevices: any;
  config: any;
  caseInsensitive: boolean = true;
  orderHeader: String = "createdAt";
  isDescOrder: boolean = true;

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private route: ActivatedRoute,
    private Router: Router
  ) {
    // this.route.queryParams.subscribe((params) => console.log("params", params));
  }

  pageTitle: any = "";
  ngOnInit(): void {
    this.url = window.location.pathname.split("/");
    if (this.url[2] === "device") {
      this.getAllDevices();
    } else {
      this.getDetails();
    }
    // console.log("url", this.url);

    // if (this.url[2] === "device" || this.url[2] === "Registered-Device") {
    //   this.pageTitle = "Print Devices";
    //   this.getDetails();
    // } else if (this.url[2] === "Active-Device") {
    //   console.log("Active-Device");
    // }
  }

  getUserDetails = (device: any) => {
    this.managementRepo.GetUserbyid(device.userId).subscribe({
      next: (response) => {
        // this.setUserData(v["data"], device);
        // this.dtTrigger.next();
      },

      error: (e) => {
        // console.log(e);
      },
    });
  };

  getDetails = () => {
    this.loading = true;
    this.managementRepo.getdevicesuser().subscribe({
      next: (response) => {
        this.loading = false;
        // this.getAllDevices()
        if (this.url[2] === "Inactive-Device") {
          this.Devices = response["data"].InActiveDevice.data;
          // this.dataLength = this.Data.length;

          this.pageTitle = "All Inactive Devices";
        } else if (this.url[2] === "Active-Device") {
          this.Devices = response["data"].ActiveDevice.data;
          // console.log("this.Data.length", this.Data.length);

          this.pageTitle = "All Active Devices";
        } else if (this.url[2] === "Registered-Device") {
          // console.log("data", response["data"].Device_count.data);
          this.Devices = response["data"].Device_count.data;

          this.pageTitle = "All Registered Devices";
        } else {
          // this.Data = response["data"];
          this.pageTitle = "No Data Found";
        }
      },

      error: (e) => {
        // console.log(e);
        this.loading = false;
      },
    });
  };

  sort(headerName: String) {
    this.isDescOrder = !this.isDescOrder;
    this.orderHeader = headerName;
  }

  handleBackNav = () => {
    history.back();
  };

  getAllDevices = () => {
    this.loading = true;
    this.managementRepo.GetAllDevice().subscribe({
      next: (v) => {
        this.Devices = v["data"];

        this.loading = false;
      },

      error: (e) => {
        // console.log(e);
      },
    });
  };

  convertToDate(dateString) {
    const date = new Date(dateString);
    return date.toDateString();
  }

  exportExcel() {
    /* pass table ID here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.url[2] + ".xlsx");
  }
}
