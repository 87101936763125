import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import { IRegisterModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ToastComponent } from "../toast/toast.component";

@Component({
  selector: "app-add-admin",
  templateUrl: "./add-admin.component.html",
  styleUrls: ["./add-admin.component.css"],
  providers: [ToastComponent],
})
export class AddAdminComponent implements OnInit {
  form: FormGroup;
  registerloading = false;
  registersuccess = false;
  errormessage = "";
  registerfailed = false;
  duplicateusername = false;
  duplicateuseremail = false;
  submitted = false;

  Roles: any[];

  SuccessMessage: string | null;
  constructor(
    private modalService: NgbModal,
    private IdentityService: IdentityService,
    private formBuilder: FormBuilder,
    private route: Router,
    private userService: IdentityService,
    private toast: ToastComponent
  ) {}

  ngOnInit(): void {
    this.GetRoles();
    this.form = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required, Validators.email])],
      password: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      fullname: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(30)]),
      ],
      role_id: ["", Validators.compose([Validators.required])],
    });
  }
  get f() {
    return this.form.controls;
  }

  GetRoles = () => {
    this.userService.Getallroles().subscribe({
      next: (v) => {
        this.Roles = v.data;
        // console.log(v.data);
      },

      error: (v) => {},
    });
  };

  CreateAccount = () => {
    const payload: IRegisterModel = this.form.value;

    this.userService.RegisterUser(payload).subscribe({
      next: (v) => {
        this.registersuccess = true;
        this.toast.successtoastNotification(" New Admin Successfully Added.");
        this.registerloading = false;
        this.SuccessMessage = " New Admin User Successfully Added .";

        setTimeout(() => {
          this.modalService.dismissAll();
          window.location.reload();
        }, 3000);
      },

      error: (error) => {
        this.registerloading = false;
        this.registerfailed = true;
        this.toast.errortoastNotification(error.responseDescription);
        this.errormessage = error.responseDescription;
      },
    });
  };
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.registerloading = true;
    this.registersuccess = false;
    this.errormessage = "";
    this.registerfailed = false;
    this.duplicateusername = false;
    this.duplicateuseremail = false;
    this.CreateAccount();
  }
}
