<div class="chart-wrapper">
  <canvas
    baseChart
    [datasets]="barChartData"
    [data]="[two_five, five_eight, eight_twleve, twleve_, fifteen_]"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [colors]="chartColors"
    [chartType]="barChartType"
  >
  </canvas>

  <div class="mt-2 mb-3">
    Age 2 - 5 = {{ two_five }}, Age 5 - 8 = {{ five_eight }}, Age 8 - 12 =
    {{ eight_twleve }}, Age 12 -15 = {{ twleve_ }}, Age 15 - 20 = {{ fifteen_ }}
  </div>
</div>
