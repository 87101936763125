import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IApiResponseModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-devices-table-view",
  templateUrl: "./devices-table-view.component.html",
  styleUrls: ["./devices-table-view.component.css"],
})
export class DevicesTableViewComponent implements OnInit {
  Data: any = [];
  dataLength: any = 2;
  userData: any = {
    name: "",
    userName: "",
    email: "",
    city: "",
    country: "",
    language: "",
    phone: "",
    deviceName: "",
    deviceStatus: "",
    salutation: "",
    createdAt: "",
  };
  Users: IApiResponseModel[];
  dtOptions: any = {};
  loading: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();
  datasuccess: boolean = false;
  dataerror: boolean = false;
  errormessage: string = "";
  successmessage: string = "";
  config: any;

  url: any = "";
  searchWord: any = "";
  orderHeader: String = "createdAt";
  isDescOrder: boolean = true;
  caseInsensitive: boolean = true;

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private Router: Router
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };

    this.route.queryParams.subscribe(
      (params) =>
        (this.config.currentPage = params["page"] ? params["page"] : 1)
    );
    this.url = window.location.pathname.split("/");
  }

  sort(headerName: String) {
    this.isDescOrder = !this.isDescOrder;
    this.orderHeader = headerName;
  }

  onPrintClick() {
    this.Router.navigate([`/print-devices/` + this.url.split("/")[2]]);
  }

  pageChange(newPage: number) {
    const title = window.location.pathname.split("/")[2];
    this.Router.navigate([`/device-view-data/${title}`], {
      queryParams: { page: newPage, size: 10 },
    });
  }

  ngOnInit(): void {
    this.getDetails();
    this.GetLoginuser();
    this.url = window.location.pathname;
  }

  loggedInUser: any;
  userRole: any;
  GetLoginuser() {
    this.AuthService.GetUser().subscribe({
      next: (v) => {
        this.loggedInUser = v.data;
      },
      error: (e) => {
        // console.log("err", e);
      },
      complete: () => {},
    });
  }

  handleBackNav = () => {
    history.back();
  };

  pageTitle: any = "";
  pageSubTitle: string = "";

  getDetails = () => {
    this.loading = true;
    this.managementRepo.getdevicesuser().subscribe({
      next: (response) => {
        this.loading = false;

        if (this.url.slice(-15) === "Inactive-Device") {
          this.Data = response["data"].InActiveDevice.data;
          this.dataLength = this.Data.length;
          this.pageTitle = "All Inactive Devices";
          this.pageSubTitle = "No app data in the last 6 months";
        } else if (this.url.slice(-13) === "Active-Device") {
          this.Data = response["data"].ActiveDevice.data;
          this.pageTitle = "All Active Devices";
        } else if (this.url.slice(-17) === "Registered-Device") {
          this.Data = response["data"].Device_count.data;
          this.pageTitle = "All Registered Devices";
        } else {
          this.pageTitle = "No Data Found";
        }
        this.dtTrigger.next();
      },

      error: (e) => {
        // console.log(e);
        this.loading = false;
      },
    });
  };

  setUserData = (data: any, device: any) => {
    this.userData.name = data.name;
    this.userData.userName = device.userName;
    this.userData.email = data.email;
    this.userData.city = data.city;
    this.userData.country = data.country;
    this.userData.language = data.language;
    this.userData.phone = data.phone;
    this.userData.deviceName = device.deviceName;
    this.userData.deviceStatus = device.status;
    this.userData.name = data.name;
    this.userData.salutation = data.salutation;
    this.userData.createdAt = device.createdAt;
    //HERE IS THE DIFERENT STATUS {assigned} OR {assigning}
    this.userData.deviceStatus =
      device.status === "assigning"
        ? "New"
        : device.status === "assigned"
        ? "Re-assigned"
        : "";
  };

  getUserDetails = (device: any) => {
    this.managementRepo.GetUserbyid(device.userId).subscribe({
      next: (v) => {
        this.setUserData(v["data"], device);
      },

      error: (e) => {
        // console.log(e);
      },
    });
  };

  deleteDevice(device: any) {
    this.dataerror = false;
    this.datasuccess = false;

    if (confirm("Are you sure you want to delete this device")) {
      this.managementRepo.deleteDeviceById(device.id).subscribe(
        (res) => {
          window.location.reload();
        },
        (err) => {
          alert("Error occured deleting user");
        }
      );
      this.loading = false;
    }
  }

  open(content: any, device: any) {
    this.getUserDetails(device);
    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      size: "xl",
    });
  }

  convertToDate(dateString) {
    const date = new Date(dateString);
    return date.toDateString();
  }
}
