import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as XLSX from "xlsx";

@Component({
  selector: "app-print-users",
  templateUrl: "./print-users.component.html",
  styleUrls: ["./print-users.component.css"],
})
export class PrintUsersComponent implements OnInit {
  Users: any = [];
  Devices: any = [];
  filename = "users.xlsx";
  url: any = [];
  loading: boolean = false;
  success: boolean = false;
  dataReady: boolean = false;
  userDevices: any;
  config: any;
  caseInsensitive: boolean = true;
  orderHeader: String = "createdAt";
  isDescOrder: boolean = true;

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private route: ActivatedRoute,
    private Router: Router
  ) {
    // this.route.queryParams.subscribe((params) => console.log("params", params));
  }

  pageTitle: any = "";
  ngOnInit(): void {
    this.url = window.location.pathname.split("/");

    if (this.url[2] === "all" || this.url[2] === "Registered-Users") {
      this.pageTitle = "Print Users";
      this.getRegisteredUser();
    } else if (this.url[2].split("-")[2] === "gt") {
      this.getUsersByNumOfGT();
    } else if (this.url[2].split("-")[2] === "age") {
      this.getUsersByAge();
    } else {
      this.getUsersByCountry();
    }
  }

  sort(headerName: String) {
    this.isDescOrder = !this.isDescOrder;
    this.orderHeader = headerName;
  }

  handleBackNav = () => {
    history.back();
  };

  getUsersByCountry = () => {
    this.loading = true;
    this.managementRepo.GetDeviceCountry().subscribe({
      next: (response) => {
        this.loading = false;

        if (this.url[2] === "Netherland") {
          this.Users = response["data"].nl.data;
          this.pageTitle = "Users from Netherland";
        } else if (this.url[2] === "Belgium") {
          this.Users = response["data"].be.data;
          this.pageTitle = "Users from Belgium";
        } else if (this.url[2] === "United-Kingdom") {
          this.Users = response["data"].uk.data;
          this.pageTitle = "Users from United Kingdom";
        } else if (this.url[2] === "France") {
          this.Users = response["data"].fr.data;
          this.pageTitle = "Users from France";
        } else if (this.url[2] === "United-State") {
          this.Users = response["data"].us.data;
          this.pageTitle = "Users from United States";
        } else if (this.url[2] === "Others") {
          this.Users = response["data"].others.data;
          this.pageTitle = "Users from Other Countries";
        } else {
          this.Users = response["data"];
          this.pageTitle = "No Data Found";
        }
      },

      error: (e) => {
        // console.log(e);
        this.loading = false;
      },
    });
  };

  getUsersByNumOfGT = () => {
    this.loading = true;
    this.managementRepo.getUsersByNumOfGT().subscribe({
      next: (response) => {
        this.loading = false;
        if (this.url[2] === "0-Gametimer-gt") {
          this.Users = response["usersWith0GT"];
          this.pageTitle = "Users With 0 Gametimer";
        } else if (this.url[2] === "1-Gametimer-gt") {
          this.Users = response["usersWith1GT"];
          this.pageTitle = "Users With 1 Gametimer";
        } else if (this.url[2] === "2-Gametimer-gt") {
          this.Users = response["usersWith2GT"];
          this.pageTitle = "Users With 2 Gametimer";
        } else if (this.url[2] === "3-Gametimer-gt") {
          this.Users = response["usersWith3GT"];
          this.pageTitle = "Users With 3 Gametimer";
        } else if (this.url[2] === "4-and-More-Gametimer-gt") {
          this.Users = response["usersWith4PlusGT"];
        } else {
          this.Users = response["data"];
          this.pageTitle = "No Data Found";
        }
      },

      error: (e) => {
        // console.log(e);
        this.loading = false;
      },
    });
  };

  getUsersByAge = () => {
    this.loading = true;
    this.managementRepo.GetAge().subscribe({
      next: (response) => {
        this.loading = false;
        if (this.url[2] === "2-5-age") {
          this.Users = response["data"].result2_5.data;
          this.pageTitle = "Users between age 2 - 5";
        } else if (this.url[2] === "5-8-age") {
          this.Users = response["data"].result5_8.data;
          this.pageTitle = "Users between age 5 - 8";
        } else if (this.url[2] === "8-12-age") {
          this.Users = response["data"].result8_12.data;
          this.pageTitle = "Users between age 8 - 12";
        } else if (this.url[2] === "12-15-age") {
          this.Users = response["data"].result12_15.data;
          this.pageTitle = "Users between age 12 - 15";
        } else if (this.url[2] === "15-20-age") {
          this.Users = response["data"].result15_20.data;
          this.pageTitle = "Users between age 15 - 20";
        } else {
          this.Users = response["data"];
        }
      },

      error: (e) => {
        // console.log(e);
        this.loading = false;
      },
    });
  };

  getRegisteredUser = () => {
    this.managementRepo.GetAllRegisteredUser().subscribe({
      next: (response) => {
        this.Users = response["data"];
      },

      error: (e) => {
        this.success = false;
        // console.log("error");
      },
    });
  };

  getAllDevices = () => {
    this.loading = true;
    this.managementRepo.GetAllDevice().subscribe({
      next: (v) => {
        this.Devices = v["data"];

        this.loading = false;
      },

      error: (e) => {
        // console.log(e);
      },
    });
  };

  convertToDate(dateString) {
    const date = new Date(dateString);
    return date.toDateString();
  }

  exportExcel() {
    /* pass table ID here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    if (this.url[2] === "all") {
      XLSX.writeFile(wb, this.url[2] + "_registered_users" + ".xlsx");
    } else {
      XLSX.writeFile(wb, this.url[2] + ".xlsx");
    }
  }
}
