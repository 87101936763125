<h1 *ngIf="Users.length === 0">Loading...</h1>
<div class="row">
  <div class="col-md-2">
    <a (click)="handleBackNav()" class="btn btn-secondary ml-3 my-3"> Back </a>
  </div>
  <div class="col-md-8">
    <h3 *ngIf="Users.length > 0" class="text-center mt-2">{{ pageTitle }}</h3>
  </div>
</div>

<div ngxCopyPaste #cp="copy">
  <div>
    <button printSectionId="print-section" class="download-btn" ngxPrint>
      Print
    </button>
    <button (click)="exportExcel()" class="download-btn">Excel</button>
    <button (click)="cp.copy()" class="download-btn">Copy</button>
  </div>

  <div *ngIf="Users.length > 0" class="table-hover table-responsive p-3" id="print-section">
    <table class="row-border hover table" id="excel-table">
      <!-- <button (click)="cp.copy()" class="download-btn">Copy</button> -->

      <thead>
        <tr class="print-headers">
          <!-- <th (click)="sort('salutation')">
            Salutation <i class="fa fa-sort"></i>
          </th> -->
          <th class="" (click)="sort('name')">
            Full Name <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('email')">Email <i class="fa fa-sort"></i></th>
          <th (click)="sort('phonePrefix')">
            Phone Prefix <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('phone')">Phone <i class="fa fa-sort"></i></th>
          <th (click)="sort('city')">City <i class="fa fa-sort"></i></th>

          <th (click)="sort('phone')">Devices <i class="fa fa-sort"></i></th>
          <th (click)="sort('country')">Country <i class="fa fa-sort"></i></th>
          <th (click)="sort('language')">
            Language <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('createdAt')">
            Date Registered <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('updatedAt')">
            Updated At <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('newsletter')">
            Newsletter <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('verified')">
            Verified <i class="fa fa-sort"></i>
          </th>
        </tr>
        <!-- Full Name, Email, Phone, City, Devices, Country Serial number, Newsletter, Language, Holiday Mode, Flight Mode, Version, User Name, User Age, Date Registered and Update At. -->

        <!--  ,  , Newsletter, Language, Date Registered and Update At. -->
      </thead>
      <tbody>
        <tr *ngFor="
            let user of Users | orderBy: orderHeader:isDescOrder:caseInsensitive
          ">
          <!-- <td>{{ user?.salutation ? user?.salutation : "nil" }}</td> -->
          <td>{{ user?.name }}</td>
          <td>{{ user?.email }}</td>
          <td>{{ user?.phonePrefix ? user?.phonePrefix : "nil" }}</td>
          <td>{{ user?.phone ? user?.phone : "nil" }}</td>
          <td>{{ user?.city ? user?.city : "nil" }}</td>
          <td>
            <app-number-of-devices [userId]="user?.id"> </app-number-of-devices>
          </td>
          <td class="text-uppercase">
            {{ user?.country ? user?.country : "nil" }}
          </td>
          <td>{{ user?.language ? user.language : "nil" }}</td>
          <td>{{ convertToDate(user.createdAt) }}</td>
          <td>{{ convertToDate(user.updatedAt) }}</td>
          <td>{{ user?.newsletter }}</td>
          <td>{{ user?.verified }}</td>
        </tr>
      </tbody>
    </table>
    <div id="content-loaded"></div>
  </div>
</div>