<div class="chart-wrapper">
  <canvas baseChart
  [data]="[assigned,assigning, unassigned,]"
  [labels]="pieChartLabels"
  [chartType]="pieChartType"
  [options]="pieChartOptions"
  [plugins]="pieChartPlugins"
  [legend]="pieChartLegend">
</canvas>

<div class=" mt-5 mb-5">

  Assigned = {{ assigned}}  ,  Assigning = {{ assigning}}, Unassigned = {{ unassigned }}

</div>
</div>
