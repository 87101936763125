import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChartType, ChartOptions } from "chart.js";
import {
  SingleDataSet,
  Label,
  monkeyPatchChartJsLegend,
  monkeyPatchChartJsTooltip,
} from "ng2-charts";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
@Component({
  selector: "app-pie-chart",
  templateUrl: "./pie-chart.component.html",
  styleUrls: ["./pie-chart.component.css"],
})
export class PieChartComponent {
  loading: boolean;
  assigned: any;
  assigning: any;
  unassigned: any;

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private avRout: ActivatedRoute
  ) {
    this.getDeviceSattus();
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit(): void {}
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = [["Assigned"], ["Assigning"], "Unassigned"];
  //public pieChartData: SingleDataSet = [30, 50, 20];
  public pieChartType: ChartType = "pie";
  public pieChartLegend = true;
  public pieChartPlugins = [];

  getDeviceSattus = () => {
    this.loading = true;
    this.managementRepo.GetDevicestatus().subscribe({
      next: (v) => {
        this.assigned = v["data"].assigned;
        this.assigning = v["data"].assigning;
        this.unassigned = v["data"].unassigned;

        this.loading = false;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
      },
    });
  };
}
