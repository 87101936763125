<h1 *ngIf="Devices.length === 0">Loading...</h1>

<div class="row">
  <div class="col-md-2">
    <a (click)="handleBackNav()" class="btn btn-secondary ml-3 my-3"> Back </a>
  </div>
  <div class="col-md-8">
    <h3 *ngIf="Devices.length > 0" class="text-center mt-2">{{ pageTitle }}</h3>
  </div>
</div>

<div ngxCopyPaste #cp="copy">
  <div>
    <button printSectionId="print-section" class="download-btn" ngxPrint>
      Print
    </button>
    <button (click)="exportExcel()" class="download-btn">Excel</button>
    <button (click)="cp.copy()" class="download-btn">Copy</button>
  </div>

  <div
    *ngIf="Devices.length > 0"
    class="table-hover table-responsive p-3"
    id="print-section"
  >
    <table class="row-border hover table" id="excel-table">
      <thead>
        <tr class="print-headers">
          <th (click)="sort('serialNumber')">
            Serial Number<i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('chipNumber')">
            Chip Number <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('deviceName')">
            Device Name <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('color')">
            Device Color <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('holidayMode')">
            Holiday Mode <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('volume')">Volume <i class="fa fa-sort"></i></th>
          <th (click)="sort('flightMode')">
            Flight Mode <i class="fa fa-sort"></i>
          </th>

          <th (click)="sort('version')">Version <i class="fa fa-sort"></i></th>
          <th (click)="sort('userName')">
            User Name <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('email')">User Email <i class="fa fa-sort"></i></th>

          <th (click)="sort('userAge')">User Age <i class="fa fa-sort"></i></th>
          <th (click)="sort('createdAt')">
            Date Registerd <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('updatedAt')">
            Update At <i class="fa fa-sort"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let user of Devices
              | orderBy: orderHeader:isDescOrder:caseInsensitive
          "
        >
          <td>{{ user.serialNumber }}</td>
          <td>{{ user.chipNumber }}</td>
          <td>{{ user.deviceName }}</td>
          <td>
            {{
              user.color === 0
                ? "Black"
                : user.color === 1
                ? "Army"
                : user.color === 2
                ? "Blue"
                : user.color === 3
                ? "Silver"
                : user.color === 4
                ? "White"
                : "nil"
            }}
          </td>
          <td>{{ user.holidayMode }}</td>
          <td>{{ user.volume }}</td>
          <td>{{ user.flightMode }}</td>
          <td>
            {{ user.version ? user.version : "Not Mentioned" }}
          </td>
          <td>{{ user.userName }}</td>
          <td>
            <app-devise-user-details [userId]="user?.userId">
            </app-devise-user-details>
          </td>
          <td>{{ user.userAge | dateAgo }}</td>
          <td>{{ convertToDate(user.createdAt) }}</td>
          <td>{{ convertToDate(user.updatedAt) }}</td>
        </tr>
      </tbody>
    </table>
    <div id="content-loaded"></div>
  </div>
</div>
