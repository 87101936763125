<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-2">
      <a (click)="handleBackNav()" class="btn btn-secondary ml-3"> Back </a>
    </div>
    <div class="col-md-8">
      <h3 class="page-titl text-center">All Devices' Logs</h3>
    </div>
  </div>
  <div class="row align-items-center mt-3">
    <div class="col-md-6">
      <button
        *ngIf="DeviceLogs?.length !== 0"
        (click)="onPrintClick()"
        class="download-btn"
      >
        Copy Data
      </button>
      <button
        *ngIf="DeviceLogs?.length !== 0"
        (click)="onPrintClick()"
        class="download-btn"
      >
        Excel Export
      </button>
      <button
        *ngIf="DeviceLogs?.length !== 0"
        (click)="onPrintClick()"
        class="download-btn"
      >
        Print Data
      </button>
    </div>
    <div class="col-md-6" *ngIf="loading === false">
      <input
        class="search-box"
        type="text"
        placeholder="Search"
        name="searchWord"
        [(ngModel)]="searchWord"
      />
    </div>
  </div>
  <div class="table-hover table-responsive p-3" id="print-section">
    <table class="row-border hover table " id="tableData">
      <thead>
        <tr class="print-headers">
          <th (click)="sort('serialNumber')">
            Serial Number <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('timeStamp')">
            Start Time <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('endTime')">End Time <i class="fa fa-sort"></i></th>

          <th (click)="sort('createdAt')">
            Date Registerd <i class="fa fa-sort"></i>
          </th>

          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let user of DeviceLogs
              | orderBy: orderHeader:isDescOrder:caseInsensitive
              | filter: searchWord
              | paginate: config
          "
        >
          <td>{{ user.serialNumber }}</td>
          <td>{{ user.timeStamp | date: "MMM d, y, h:mm:ss a" }}</td>
          <td>{{ user.endTime | date: "MMM d, y, h:mm:ss a" }}</td>

          <td>{{ convertToDate(user.createdAt) }}</td>
          <td>
            <a [routerLink]="['/log-action/', user.id]" class="btn btn-primary">
              View Actions</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="DeviceLogs.length > 10">
      <pagination-controls
        (pageChange)="pageChange($event)"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>
</div>

<app-spinner *ngIf="loading" [loading]="true"></app-spinner>
