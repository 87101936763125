import { state } from '@angular/animations';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IdentityService } from '../Services/identity.service';





@Injectable({providedIn: 'root'})
export class UserGaurd implements CanActivate{

constructor( private UserService: IdentityService, private Router: Router){}

canActivate(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {


  //return this.permissions.canActivate(this.currentUser, route.params.id);

  if (this.UserService.Isuserlogin()){
    this.UserService.ReturnUrl = state.url;
    return true;

  } else{
    // alert('Pls Log LogIn to Proceed ')
   this.Router.navigate( ['/login'], { queryParams: {ReturnUrl: state.url}});
    return false;

  }
}
}



