import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import {
  NgxAwesomePopupModule,
  ToastNotificationConfigModule,
} from "@costlydeveloper/ngx-awesome-popup";
import { NgxSpinnerComponent, NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule } from "ngx-pagination";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./component/login/login.component";
import { SpinnerComponent } from "./component/spinner/spinner.component";
import { ToastComponent } from "./component/toast/toast.component";
import { RequestInterceptor } from "./Helper/interceptor";
import { IdentityService } from "./Services/identity.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { ProfileComponent } from "./views/profile/profile.component";
import { LayoutViewComponent } from "./views/layout-view/layout-view.component";
import { HeaderComponent } from "./component/header/header.component";
import { AddAdminComponent } from "./component/add-admin/add-admin.component";
import { RegisterUsersComponent } from "./views/register-users/register-users.component";
import { ActiveUsersComponent } from "./views/active-users/active-users.component";
import { UsersLocationComponent } from "./views/users-location/users-location.component";
import { UsersGenderComponent } from "./views/users-gender/users-gender.component";
import { ManagementService } from "./Services/management.service";
import { DataTablesModule } from "angular-datatables";
import { ChartsModule } from "ng2-charts";
import { BarChartComponent } from "./component/bar-chart/bar-chart.component";
import { BubbleChartComponent } from "./component/bubble-chart/bubble-chart.component";
import { LineChartComponent } from "./component/line-chart/line-chart.component";
import { PieChartComponent } from "./component/pie-chart/pie-chart.component";
import { RadarChartComponent } from "./component/radar-chart/radar-chart.component";
import { DoughnutChartComponent } from "./component/doughnut-chart/doughnut-chart.component";
import { DateAgoPipe } from "./pipes/date-ago.pipe";
import { DeviceByuserComponent } from "./component/device-byuser/device-byuser.component";
import { DeviceLogsComponent } from "./component/device-logs/device-logs.component";
import { DevicelogByserialnumberComponent } from "./component/devicelog-byserialnumber/devicelog-byserialnumber.component";
import { LogActionComponent } from "./component/log-action/log-action.component";
import { TableViewComponent } from "./component/table-view/table-view.component";
import { ChartDataComponent } from "./component/chart-data/chart-data.component";
import { DeviceAccountComponent } from "./component/shared-views/device-account/device-account.component";
import { UsersCountryComponent } from "./component/users-country/users-country.component";
import { UsersAgeComponent } from "./component/users-age/users-age.component";
import { DevicesTableViewComponent } from "./component/devices-table-view/devices-table-view.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { Ng2OrderModule } from "ng2-order-pipe";
import { FormsModule } from "@angular/forms";
import { RequestResetPasswordComponent } from "./views/request-reset-password/request-reset-password.component";
import { ResetPasswordComponent } from "./views/reset-password/reset-password.component";
import { RouterModule } from "@angular/router";
import { NumberOfDevicesComponent } from "./views/register-users/number-of-devices/number-of-devices.component";
import { UsersByNumberOfGtComponent } from "./component/users-by-number-of-gt/users-by-number-of-gt.component";
import { UsersByGtDataComponent } from "./component/users-by-gt-data/users-by-gt-data.component";
import { PrintUsersComponent } from "./views/print-data/print-users/print-users.component";
import { AdminUsersComponent } from "./views/admin-users/admin-users.component";
import { OrderModule } from "ngx-order-pipe";
import { FilterPipeModule } from "ngx-filter-pipe";
import {NgxPrintModule} from 'ngx-print';
import { ExportDataComponent } from './views/export-data/export-data.component';
import {NgxCopyPasteModule} from 'ngx-copypaste';
import { PrintDeviceComponent } from './views/print-device/print-device.component';
import { PrintAdminComponent } from './views/print-admin/print-admin.component';
import { PrintLogsComponent } from './views/print-logs/print-logs.component';
import { DeviseUserDetailsComponent } from './component/devise-user-details/devise-user-details.component'

///
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SpinnerComponent,
    ToastComponent,
    DashboardComponent,
    ProfileComponent,
    LayoutViewComponent,
    HeaderComponent,
    AddAdminComponent,
    RegisterUsersComponent,
    ActiveUsersComponent,
    UsersLocationComponent,
    UsersGenderComponent,
    BarChartComponent,
    BubbleChartComponent,
    LineChartComponent,
    PieChartComponent,
    RadarChartComponent,
    DoughnutChartComponent,
    DateAgoPipe,
    DeviceByuserComponent,
    DeviceLogsComponent,
    DevicelogByserialnumberComponent,
    LogActionComponent,
    TableViewComponent,
    ChartDataComponent,
    DeviceAccountComponent,
    UsersCountryComponent,
    UsersAgeComponent,
    DevicesTableViewComponent,
    RequestResetPasswordComponent,
    ResetPasswordComponent,
    NumberOfDevicesComponent,
    UsersByNumberOfGtComponent,
    UsersByGtDataComponent,
    PrintUsersComponent,
    AdminUsersComponent,
    ExportDataComponent,
    PrintDeviceComponent,
    PrintAdminComponent,
    PrintLogsComponent,
    DeviseUserDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ChartsModule,
    NgxAwesomePopupModule.forRoot(),
    ToastNotificationConfigModule.forRoot(),
    NgbModule,
    DataTablesModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    FormsModule,
    OrderModule,
    FilterPipeModule,
    NgxPrintModule,
    NgxCopyPasteModule,
    RouterModule.forRoot([]),
  ],
  providers: [
    IdentityService,
    ManagementService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
