import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IApiResponseModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-users-age",
  templateUrl: "./users-age.component.html",
  styleUrls: ["./users-age.component.css"],
})
export class UsersAgeComponent implements OnInit {
  countryParams: string;
  Data: any;
  Users: IApiResponseModel[];
  dtOptions: any = {};
  loading: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();
  config: any;

  url: any = "";
  orderHeader: String = "createdAt";
  isDescOrder: boolean = true;
  caseInsensitive: boolean = true;
  searchWord: any = "";


  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private route: ActivatedRoute,
    private Router: Router
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };

    this.route.queryParams.subscribe(
      (params) =>
        (this.config.currentPage = params["page"] ? params["page"] : 1)
    );

    this.url = window.location.pathname.split("/");

  }

  sort(headerName: String) {
    this.isDescOrder = !this.isDescOrder;
    this.orderHeader = headerName;
  }

  onPrintClick() {
    this.Router.navigate([`/print-users/` + this.url.split('/')[2] + '-age']);
  }

  convertToDate(dateString) {
    const date = new Date(dateString);
    return date.toDateString();
  }

  pageChange(newPage: number) {
    const title = window.location.pathname.split("/")[2];
    this.Router.navigate([`/users-age-data/${title}`], {
      queryParams: { page: newPage, size: 10 },
    });
  }

  ngOnInit(): void {
    this.GetLoginuser();
    this.url = window.location.pathname;
    this.getDetails();

   
  }

  loggedInUser: any;
  userRole: any;
  GetLoginuser() {
    this.AuthService.GetUser().subscribe({
      next: (v) => {
        this.loggedInUser = v.data;
      },
      error: (e) => {
        // console.log("err", e);
      },
      complete: () => {},
    });
  }

  handleBackNav = () => {
    history.back();
  };

  pageTitle: string = "";
  getDetails = () => {
    this.loading = true;
    this.managementRepo.GetAge().subscribe({
      next: (response) => {
        this.loading = false;

        if (this.url.slice(-3) === "2-5") {
          this.Data = response["data"].result2_5.data;
          this.pageTitle = "Users between age 2 - 5";
        } else if (this.url.slice(-3) === "5-8") {
          this.Data = response["data"].result5_8.data;
          this.pageTitle = "Users between age 5 - 8";
        } else if (this.url.slice(-4) === "8-12") {
          this.Data = response["data"].result8_12.data;
          this.pageTitle = "Users between age 8 - 12";
        } else if (this.url.slice(-5) === "12-15") {
          this.Data = response["data"].result12_15.data;
          this.pageTitle = "Users between age 12 - 15";
        } else if (this.url.slice(-5) === "15-20") {
          this.Data = response["data"].result15_20.data;
          this.pageTitle = "Users between age 15 - 20";
        } else {
          this.Data = response["data"];
        }

        this.dtTrigger.next();
      },

      error: (e) => {
        this.loading = false;
      },
    });
  };

  deleteUser(user: any) {
    if (confirm("Are you sure you want to delete this user")) {
      this.managementRepo.deleteUserById(user.id).subscribe(
        (res) => {
          window.location.reload();
        },
        (err) => {
          alert("Error occured deleting user");
        }
      );
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
