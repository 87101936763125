<app-spinner *ngIf="registerloading" [loading]="true"></app-spinner>
<div class="container mt-2">
  <div class="row"></div>
  <div class="row">
    <div class="col-md-7">
      <i class="fa fa-user-circle fa-5x"> </i>
      <h5>
        User Name : <span> {{ User.fullname }} </span>
      </h5>
      <p></p>

      <h5>
        Email : <span> {{ User.email }} </span>
      </h5>
      <p></p>

      <h5>
        Role : <span> {{ role }} </span>
      </h5>
      <p></p>

      <h5>
        Date Created : <span> {{ User.createdAt | date }} </span>
      </h5>
      <p></p>
    </div>

    <div class="col-md-5">
      <div class="card">
        <h4 class="card-header">Update Account</h4>
        <div class="card-body">
          <div
            *ngIf="registerfailed"
            class="alert alert-success fade show"
            role="alert"
          >
            * {{ SuccessMessage }}
          </div>
          <div
            *ngIf="registerfailed"
            class="alert alert-danger fade show"
            role="alert"
          >
            * {{ errormessage }}
          </div>

          <div
            *ngIf="duplicateuseremail"
            class="alert alert-danger fade show"
            role="alert"
          >
            * Email Already Exist
          </div>

          <div
            *ngIf="duplicateusername"
            class="alert alert-danger fade show"
            role="alert"
          >
            * User Name Already Exist
          </div>

          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="text"
                value="{{ User.email }}"
                readonly
                class="form-control input"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              />
            </div>

            <div class="form-group">
              <label for="email">User Name</label>
              <input
                type="text"
                value="{{ User.fullname }}"
                readonly
                class="form-control input"
                [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }"
              />
            </div>

            <div class="form-group">
              <label for="password">Current Password</label>
              <input
                type="password"
                formControlName="password"
                class="form-control input"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
              />
              <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.password.errors.required">
                  Password is required
                </div>
                <div *ngIf="f.password.errors.minlength">
                  Password is too Weak
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="npassword">New Password</label>
              <input
                type="password"
                formControlName="npassword"
                class="form-control input"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
              />
              <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.password.errors.required">
                  Password is required
                </div>
                <div *ngIf="f.password.errors.minlength">
                  Password is too Weak
                </div>
              </div>
            </div>

            <div class="form-group">
              <button
                [disabled]="registerloading"
                class="btn loginbtn input btn-block"
              >
                <span
                  *ngIf="registerloading"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                Update Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
