<div class="container-fluid pt-3">
  <a (click)="handleBackNav()" class="btn btn-secondary ml-3"> Back </a>

  <div *ngIf="dataerror" class="alert-danger alert">
    {{ errormessage }}
  </div>

  <div *ngIf="datasuccess" class="alert-success alert">
    {{ successmessage }}
  </div>

  <h3 class="text-center">
    Device(s) registered under -<strong>
      {{ User.name ? User.name : "" }}</strong>
  </h3>
  <div *ngIf="!success && !loading" class="card">
    <h5 class="text-danger text-center p-5">
      No Device Record Found for User - {{ User.name ? User.name : "" }}
    </h5>
  </div>

  <div>
    <div class="table-hover table-responsive p-3" id="print-section">
      <table class="row-border hover table" id="tableData">
        <thead *ngIf="success">
          <tr class="print-headers">
            <th>Serial Number</th>

            <th>Holiday Mode</th>
            <th>Flight Mode</th>
            <th>Version</th>
            <th>User Name</th>
            <th>User Age</th>
            <th>Date Registerd</th>

            <th>Action</th>
          </tr>
        </thead>
        <tbody *ngIf="Device?.length > 0">
          <tr *ngFor="let device of Device | paginate: config">
            <td>{{ device.serialNumber }}</td>
            <td>{{ device.holidayMode }}</td>
            <td>{{ device.flightMode }}</td>
            <td>
              {{
              device.version
              ?device.version.length === 32
              ?device.version.slice(8, 12)
              :device.version.length ===33
              ?device.version.slice(8, 13)
              :device.version
              ?device.version
              :Nill
              :Nill
              }}
            </td>
            <td>{{ device.userName }}</td>
            <td>{{ device.userAge | dateAgo }}</td>
            <td>{{ convertToDate(device.createdAt) }}</td>

            <td>
              <div class="dropdown">
                <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  More
                </a>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a type="button" class="dropdown-item text-info" (click)="open(content, device)">View Details</a>

                  <a [routerLink]="[
                      '/devicelog-by-serialn/',
                      device.serialNumber,
                      device.userId
                    ]" class="dropdown-item text-warning">
                    View Logs</a>

                  <a *ngIf="loggedInUser?.role_id !== 3" type="button" class="dropdown-item text-danger"
                    (click)="deleteDevice(device)">
                    Remove Device
                  </a>
                </div>
              </div>
            </td>

            <h1 *ngIf="toggleBox">DISPLAY BOX</h1>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="Device.length > 12">
      <pagination-controls (pageChange)="pageChange($event)" class="my-pagination"></pagination-controls>
    </div>
  </div>

  <ng-template #content let-modal>
    <h1 class="modal-title w-100 text-center" id="modal-basic-title">
      {{ userData.userName }}
    </h1>
    <div class="modal-body">
      <div class="row">
        <!-- <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Salutation</label>
            <input type="email" class="form-control modal-form" id="exampleFormControlInput1"
              value="{{ userData.salutation ? userData.salutation : 'nil' }}" readonly />
          </div>
        </div> -->
        <div class="col">
          <div class="mb-4">
            <label for="exampleFormControlInput1" class="form-label">Name</label>
            <input type="email" class="form-control modal-form" id="exampleFormControlInput1"
              value="{{ userData.name }}" readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-4">
            <label for="exampleFormControlInput1" class="form-label">User Name</label>
            <input type="email" class="form-control modal-form" id="exampleFormControlInput1"
              value="{{ userData.userName }}" readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-4">
            <label for="exampleFormControlInput1" class="form-label">Email</label>
            <input type="email" class="form-control modal-form" id="exampleFormControlInput1"
              value="{{ userData.email }}" readonly />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Country</label>
            <input type="email" class="form-control modal-form" id="exampleFormControlInput1"
              value="{{ userData.country }}" readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">City</label>
            <input type="email" class="form-control modal-form" id="exampleFormControlInput1"
              value="{{ userData.city }}" readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Language</label>
            <input type="email" class="form-control modal-form" id="exampleFormControlInput1"
              value="{{ userData.language }}" readonly />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
            <input type="text" class="form-control modal-form" id="exampleFormControlInput1"
              value="{{ userData.phone ? userData.phone : 'nil' }}" readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Device Name</label>
            <input type="text" class="form-control modal-form" id="exampleFormControlInput1"
              value="{{ userData.deviceName }}" readonly />
          </div>
        </div>
        <div class="col">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Device Status</label>
            <input type="email" class="form-control modal-form" id="exampleFormControlInput1"
              value="{{ userData.deviceStatus }}" readonly />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Close
      </button>
    </div>
  </ng-template>

  <app-spinner *ngIf="loading" [loading]="true"></app-spinner>
</div>