import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { IApiResponseModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as XLSX from "xlsx";

@Component({
  selector: "app-users-gender",
  templateUrl: "./users-gender.component.html",
  styleUrls: ["./users-gender.component.css"],
})
export class UsersGenderComponent implements OnInit {
  Users: any = [];

  DeviceLogs: any = [];
  filename = "ExcelSheet.xlsx";
  url: any = [];
  loading: boolean = false;
  success: boolean = false;
  dataReady: boolean = false;
  userDevices: any;
  config: any;
  caseInsensitive: boolean = true;
  orderHeader: String = "createdAt";
  isDescOrder: boolean = true;
  searchWord: any = "";

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private route: ActivatedRoute,
    private Router: Router
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };

    this.route.queryParams.subscribe(
      (params) =>
        (this.config.currentPage = params["page"] ? params["page"] : 1)
    );
    this.url = window.location.pathname.split("/");
  }

  handleBackNav = () => {
    history.back();
  };

  sort(headerName: String) {
    this.isDescOrder = !this.isDescOrder;
    this.orderHeader = headerName;
  }

  onPrintClick() {
    this.Router.navigate([`/print-logs/` + this.url[1]]);
  }

  convertToDate(dateString) {
    const date = new Date(dateString);
    return date.toDateString();
  }
  
  pageChange(newPage: number) {
    this.Router.navigate(["/devicelog"], {
      queryParams: { page: newPage, size: 10 },
    });
  }
  ngOnInit(): void {
    this.getAllDevices();
  }

  getUserLocation = () => {
    this.managementRepo.GetAllRegisteredUser().subscribe({
      next: (v) => {
        this.Users = v["data"];
      },

      error: (e) => {
        // console.log(e);
      },
    });
  };

  viewdevice(sn) {}
  getAllDevices = () => {
    this.loading = true;
    this.managementRepo.GetAllDeviceLogs().subscribe({
      next: (v) => {
        this.DeviceLogs = v["data"];
        this.loading = false;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
      },
    });
  };

  exportExcel() {
    /* pass table ID here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.filename);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
  }
}
