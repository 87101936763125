import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { IApiResponseModel, ILoginModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ToastComponent } from "../toast/toast.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  providers: [ToastComponent],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  //form: FormGroup = new FormGroup({
  // email: new FormControl(''),
  //password:  new FormControl()
  //});
  loginloading: boolean = false;
  loginsuccess: boolean = false;
  errormessage: string = "";
  loginefailed: boolean = false;
  submitted: boolean = false;
  errorMessage: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private route: Router,
    private Repo: IdentityService,
    private toast: ToastComponent
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required, Validators.email])],
      password: ["", Validators.compose([Validators.required])],
    });
  }

  get f() {
    return this.form.controls;
  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const payload: ILoginModel = this.form.value;

    this.Login(payload);
  }

  Login = (payload: ILoginModel) => {
    this.loginefailed = false;
    this.loginloading = true;
    this.loginsuccess = false;
    this.loginefailed = false;
    this.submitted = false;
    this.errorMessage = "";

    this.Repo.login(payload).subscribe({
      /// successfulll
      next: (v: IApiResponseModel) => {
        (this.loginloading = false),
          (this.loginefailed = false),
          (this.loginsuccess = true),
          this.toast.successtoastNotification(v.responseDescription);
        localStorage.setItem("token", v.token);
        this.route.navigate(["/"]);
      },

      error: (e: IApiResponseModel) => {
        (this.loginefailed = true),
          (this.loginloading = false),
          (this.errorMessage = e.responseDescription),
          this.toast.errortoastNotification(e.responseDescription);
        localStorage.clear();
      },
      complete: () => console.info("complete"),
    });
  };
}
