import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Color, Label } from "ng2-charts";
import { ManagementService } from "src/app/Services/management.service";
import { SharedService } from "src/app/Services/shared.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  Data: any = {};
  loading: boolean = false;

  public chartColors: any[] = [
    {
      backgroundColor: ["#E64F22", "#000000", "green", "grey", "blue"],
    },
  ];

  barChartLabels: Label[] = [
    "Registered Users",
    "Registered Device",
    "Active Device",
    "Inactive Device",
    "Users without Gametimer",
  ];

  barChartType: ChartType = "bar";
  barChartLegend = true;
  barChartPlugins = [];

  barChartData: ChartDataSets[] = [{ label: "Device and account" }];

  constructor(
    private service: ManagementService,
    private router: Router,
    private shared: SharedService
  ) {
    this.getdetails();
  }

  ngOnInit(): void {
  }

  barChartOptions: ChartOptions = {
    responsive: true,
    onClick: function (e) {
      var element = this.getElementAtEvent(e);
      if (element.length && element[0]._view.label === "Registered Users") {
        const text1 = element[0]._view.label.split(" ")[0];
        const text2 = element[0]._view.label.split(" ")[1];
        window.location.assign(
          window.location.origin + `/view-data/${text1}-${text2}`
        );
      } else if (
        element.length &&
        element[0]._view.label === "Users without Gametimer"
      ) {
        const text1 = element[0]._view.label.split(" ")[0];
        const text2 = element[0]._view.label.split(" ")[1];
        const text3 = element[0]._view.label.split(" ")[2];
        window.location.assign(
          window.location.origin + `/view-data/${text1}-${text2}-${text3}`
        );
      } else {
        const text1 = element[0]._view.label.split(" ")[0];
        const text2 = element[0]._view.label.split(" ")[1];
        window.location.assign(
          window.location.origin + `/device-view-data/${text1}-${text2}`
        );
      }
    },
  };

  getdetails = () => {
    this.loading = true;
    this.service.getdevicesuser().subscribe({
      next: (v) => {
        this.Data = v["data"];
        this.loading = false;
      },

      error: (e) => {
        // console.log("error1", e), (this.loading = false);
      },
    });
  };
}
