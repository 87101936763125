<app-spinner *ngIf="loginloading" [loading]="true"></app-spinner>
<div class="container">
  <div class="logo"></div>
  <div class="row align-items-center">
    <div class="col-md-6 login-bg"></div>
    <div class="col-md-6 form-col pt-5">
      <div class="card border-0">
        <h4 class="card-header csutom-color border-0">
          <strong class="login-border">Login</strong> <strong> as Admin</strong>
        </h4>
        <div class="card-body">
          <div
            *ngIf="loginefailed"
            class="alert alert-danger fade show"
            role="alert"
          >
            * {{ errorMessage }}
          </div>

          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <div class="input-group">
                <input
                  type="text"
                  formControlName="email"
                  class="form-control input"
                  placeholder="Email"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                />
                <div class="input-group-text">
                  <i class="fas fa-envelope"></i>
                </div>
              </div>

              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">
                  Enter a Valid Email Address
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <input
                  type="password"
                  formControlName="password"
                  class="form-control input"
                  placeholder="Password"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                />
                <div class="input-group-text">
                  <i class="fas fa-lock"></i>
                </div>
              </div>
              <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.password.errors.required">
                  Password is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <button
                [disabled]="loginloading"
                class="btn btn-block loginbtn input"
              >
                <span
                  *ngIf="loginloading"
                  class="spinner-border spinner-border-sm mr-1"
                  [disabled]="loginloading"
                ></span>
                Login
              </button>
              <!-- <a [routerLink]="['/request-password-reset']" class="text-center mt-4">Forgot Password ?</a> -->
              <p class="text-center mt-4">
                <a href="/request-password-reset">Forgot Password ?</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
