import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, scheduled } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  message: any;
  private sharedData = new BehaviorSubject<[1,2,3]>(null);

  constructor() {}

  setData(data: any) {
    this.sharedData.next(data);
    // this.message = data;
  }

  getData(): Observable<any> {
    return this.sharedData.asObservable();
  }
}
