import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import {
  IApiRequestModel,
  IApiRequestResponseModel,
  IApiResponseModel,
  ILoginModel,
  IRegisterModel,
} from "src/app/interface/interface";
import { LoginMode } from "../model/models";

@Injectable({
  providedIn: "root",
})
export class IdentityService {
  constructor(private httpClient: HttpClient) {}

  ReturnUrl: string = "";

  requestPasswordReset(data) {
    return this.httpClient.post(
      environment.BaseUrl + "identity/passwordResetRequest",
      data
    );
  }

  updatePasswordReset(data) {
    return this.httpClient.post(
      environment.BaseUrl +
        "identity/updateForgotPassword" +
        "/" +
        data.userId +
        "/" +
        data.resetString,
      data
    );
  }

  getAllAdminUsers() {
    return this.httpClient.get(
      environment.BaseUrl +
        "identity/getAlluser"
    );
  }

  deleteAdminUser(superAdmin:any, userId:any) {
    return this.httpClient.delete(
      environment.BaseUrl +
        "identity/DeleteUser" +
        "/" +
        superAdmin +
        "/" +
        userId
    );
  }

  login(payload: ILoginModel): Observable<IApiResponseModel> {
    return this.httpClient.post<IApiResponseModel>(
      environment.BaseUrl + "identity/login",
      { email: payload.email, password: payload.password }
    );
  }

  FindEmail(email: string): Observable<IApiResponseModel> {
    return this.httpClient.get<IApiResponseModel>(
      environment.BaseUrl + "Identity/findemail/" + email
    );
  }

  Findusername(username: string): Observable<IApiResponseModel> {
    return this.httpClient.get<IApiResponseModel>(
      environment.BaseUrl + "Identity/findusername/" + username
    );
  }

  RegisterUser(payload: IRegisterModel): Observable<IApiResponseModel> {
    return this.httpClient.post<IApiResponseModel>(
      environment.BaseUrl + "Identity/createaccount",
      {
        fullname: payload.fullname,
        email: payload.email,
        password: payload.password,
        role_id: payload.role_id,
      }
    );
  }

  

  sendResetPasswordRequest(
    payload: IApiRequestModel
  ): Observable<IApiRequestResponseModel> {
    return this.httpClient.post<IApiRequestResponseModel>(
      environment.BaseUrl + "Identity/passwordResetRequest",
      {
        email: payload.email,
        redirectUrl: payload.redirectUrl,
      }
    );
  }
  p;

  setToken(token: string) {
    localStorage.setItem("token", token);
  }

  getToken() {
    return localStorage.getItem("token");
  }

  Isuserlogin() {
    if (this.getToken() === null) {
      return false;
    } else {
      return true;
    }
  }

  Getallroles(): Observable<IApiResponseModel> {
    return this.httpClient.get<IApiResponseModel>(
      environment.BaseUrl + "identity/getroles"
    );
  }

  LogOut() {
    return this.httpClient.delete(environment.BaseUrl + "identity/logout");
  }

  GetUser(): Observable<IApiResponseModel> {
    return this.httpClient.get<IApiResponseModel>(
      environment.BaseUrl + "identity/getcurrentadmin"
    );
  }

  GetAdminRoles = (): Observable<IApiResponseModel> => {
    return this.httpClient.get<IApiResponseModel>(
      environment.BaseUrl + "identity/getroles"
    );
  };
}
