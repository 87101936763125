import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-admin-users",
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.css"],
})
export class AdminUsersComponent implements OnInit {
  Users: any = [];
  isLoading: any = true;

  loading: boolean = true;
  dtTrigger: Subject<any> = new Subject<any>();
  userDevices: any;
  config: any;

  url: any = "";
  orderHeader: String = "createdAt";
  isDescOrder: boolean = true;
  caseInsensitive: boolean = true;
  searchWord: any = "";

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private route: ActivatedRoute,
    private Router: Router
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };

    this.route.queryParams.subscribe(
      (params) =>
        (this.config.currentPage = params["page"] ? params["page"] : 1)
    );
    this.url = window.location.pathname.split("/");
  }

  sort(headerName: String) {
    this.isDescOrder = !this.isDescOrder;
    this.orderHeader = headerName;
  }

  onPrintClick() {
    this.Router.navigate([`/print-admin/` + this.url[1]]);
  }

  pageChange(newPage: number) {
    this.Router.navigate(["/admin-users"], {
      queryParams: { page: newPage, size: 10 },
    });
  }

  ngOnInit(): void {
    this.GetLoginuser();
    this.getRegistereAdmindUsers();
  }

  loggedInUser: any;
  userRole: any;
  GetLoginuser() {
    this.AuthService.GetUser().subscribe({
      next: (v) => {
        this.loggedInUser = v.data;
      },
      error: (e) => {
        // console.log("err", e);
      },
    });
  }

  handleBackNav = () => {
    history.back();
  };

  getRegistereAdmindUsers = () => {
    this.loading = true;
    this.AuthService.getAllAdminUsers().subscribe({
      next: (response) => {
        this.Users = response;

        this.dtTrigger.next();
        this.loading = false;
      },

      error: (e) => {
        // console.log(e);
        this.loading = false;
      },
    });
  };

  deleteUser(userId: any) {
    if (confirm("Are you sure you want to delete this user")) {
      this.loading = true;
      this.AuthService.deleteAdminUser(this.loggedInUser.id, userId).subscribe(
        (res) => {
          window.location.reload();
        },
        (err) => {
          // console.log("error", err);
          alert("Error occured deleting user");
        }
      );
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
