import { Component, OnInit, Input } from "@angular/core";
import { ManagementService } from "src/app/Services/management.service";

@Component({
  selector: "app-number-of-devices",
  templateUrl: "./number-of-devices.component.html",
  styleUrls: ["./number-of-devices.component.css"],
})
export class NumberOfDevicesComponent implements OnInit {
  @Input("userId") userId: string;
  Devices: any;
  constructor(private managementRepo: ManagementService) {}

  ngOnInit(): void {
    this.getUserDevices(this.userId);
  }

  getUserDevices = (id: any) => {
    // this.loading = true;
    this.managementRepo.getdevicebyuser(id).subscribe({
      next: (v) => {
        this.Devices = v["data"].length;
      },

      error: (e) => {
        // console.log('j',e);
      },
    });
  };
}
