<div class="container-fluid pt-3">
  <div class="row">
    <div class="col-md-2">
      <a (click)="handleBackNav()" class="btn btn-secondary ml-3"> Back </a>
    </div>
    <div class="col-md-8">
      <h3 class="page-titl text-center">All Registered Users</h3>
    </div>
  </div>
  <div class="row align-items-center mt-3">
    <div class="col-md-6">
      <button
        *ngIf="Users?.length !== 0"
        (click)="onPrintClick()"
        class="download-btn"
      >
        Print Data
      </button>
      <button
        *ngIf="Users?.length !== 0"
        (click)="onPrintClick()"
        class="download-btn"
      >
        Excel Export
      </button>
      <button
        *ngIf="Users?.length !== 0"
        (click)="onPrintClick()"
        class="download-btn"
      >
        Copy Data
      </button>
    </div>
    <div class="col-md-6" *ngIf="loading === false">
      <input
        class="search-box"
        type="text"
        placeholder="Search"
        name="searchWord"
        [(ngModel)]="searchWord"
      />
    </div>
  </div>

  <div class="table-hover table-responsive p-3" id="print-section">
    <table class="row-border hover table" id="tableData">
      <thead>
        <tr class="print-headers">
          <th (click)="sort('name')" style="min-width: 180px">
            Full Name <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('email')">Email <i class="fa fa-sort"></i></th>
          <th>
            Verified
            <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('phone')">Phone <i class="fa fa-sort"></i></th>
          <th (click)="sort('city')">City <i class="fa fa-sort"></i></th>
          <th (click)="sort('')">Devices <i class="fa fa-sort"></i></th>
          <th (click)="sort('country')">Country <i class="fa fa-sort"></i></th>
          <th (click)="sort('createdAt')">
            Date Registered <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('newsletter')">
            Newsletter <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('isWalletActive')">
            Active Wallet <i class="fa fa-sort"></i>
          </th>

          <th>Action</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let user of Users
              | orderBy : orderHeader : isDescOrder : caseInsensitive
              | filter : searchWord
              | paginate : config
          "
        >
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>
            <i
              *ngIf="user.verified === true"
              class="fa fa-check"
              style="color: #28a745"
            ></i>
            <i
              *ngIf="user.verified === false"
              class="fa fa-times"
              style="color: #dc3545"
            ></i>
          </td>

          <td>{{ user.phone }}</td>
          <td>{{ user.city }}</td>
          <td>
            <app-number-of-devices [userId]="user?.id"> </app-number-of-devices>
          </td>
          <td class="text-uppercase">{{ user?.country }}</td>
          <td>{{ convertToDate(user.createdAt) }}</td>
          <td>
            <i
              *ngIf="user.newsletter === true"
              class="fa fa-check"
              style="color: #28a745"
            ></i>
            <i
              *ngIf="user.newsletter === false"
              class="fa fa-times"
              style="color: #dc3545"
            ></i>
          </td>

          <td>
            <i
              *ngIf="user.isWalletActive === true"
              class="fa fa-check"
              style="color: #28a745"
            ></i>
            <i
              *ngIf="user.isWalletActive === false"
              class="fa fa-times"
              style="color: #dc3545"
            ></i>
          </td>
          <td>
            <a [routerLink]="['/device/', user?.id]" class="btn btn-primary">
              View Account
            </a>
          </td>
          <td>
            <button
              *ngIf="loggedInUser?.role_id !== 3"
              class="btn btn-danger"
              (click)="deleteUser(user)"
            >
              Delete User
            </button>
          </td>

          <td>
            <button
              *ngIf="user.verified === false"
              class="btn btn-primary"
              (click)="verifyUserUser(user)"
            >
              Verify User
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="Users?.length > 10">
  <pagination-controls
    (pageChange)="pageChange($event)"
    class="my-pagination"
  ></pagination-controls>
</div>
<app-spinner *ngIf="loading" [loading]="true"></app-spinner>
