import { Component, OnInit, Input } from "@angular/core";
import { ManagementService } from "src/app/Services/management.service";

@Component({
  selector: "app-devise-user-details",
  templateUrl: "./devise-user-details.component.html",
  styleUrls: ["./devise-user-details.component.css"],
})
export class DeviseUserDetailsComponent implements OnInit {
  @Input("userId") userId: string;
  UserDetails: any;

  constructor(private managementRepo: ManagementService) {}

  ngOnInit(): void {
    this.getUserDetails(this.userId);
  }

  getUserDetails = (id: any) => {
    // this.loading = true;

    if (id) {
      this.managementRepo.GetUserbyid(id).subscribe({
        next: (response) => {
          this.UserDetails = response["data"].email;
        },

        error: (e) => {
          // console.log("j", e);
          // this.success = false;
        },
      });
    } else {
      this.UserDetails = "nil";
    }
  };
}
