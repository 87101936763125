import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { Subject } from "rxjs";
import { IApiResponseModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-users-location",
  templateUrl: "./users-location.component.html",
  styleUrls: ["./users-location.component.css"],
})
export class UsersLocationComponent implements OnInit {
  config: any;
  datasuccess: boolean = false;
  dataerror: boolean = false;
  userData: any = {
    name: "",
    userName: "",
    email: "",
    city: "",
    country: "",
    language: "",
    phone: "",
    deviceName: "",
    salutation: "",
    deviceStatus: "",
    createdAt: "",
  };

  url: any = "";
  searchWord: any = "";
  orderHeader: String = "createdAt";
  isDescOrder: boolean = true;
  caseInsensitive: boolean = true;

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private Router: Router
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };

    this.route.queryParams.subscribe(
      (params) =>
        (this.config.currentPage = params["page"] ? params["page"] : 1)
    );

    this.url = window.location.pathname.split("/");
  }

  Users: IApiResponseModel[];
  Devices: any = [];
  dtOptions: any = {};
  loading: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();

  sort(headerName: String) {
    this.isDescOrder = !this.isDescOrder;
    this.orderHeader = headerName;
  }

  onPrintClick() {
    this.Router.navigate([`/print-devices/` + this.url[1]]);
  }

  pageChange(newPage: number) {
    this.Router.navigate(["/device"], {
      queryParams: { page: newPage, size: 10 },
    });
  }

  ngOnInit(): void {
    this.GetLoginuser();
    this.getAllDevices();

    // this.calculateAge()
  }

  loggedInUser: any;
  userRole: any;
  GetLoginuser() {
    this.AuthService.GetUser().subscribe({
      next: (v) => {
        this.loggedInUser = v.data;
      },
      error: (e) => {
        // console.log("err", e);
      },
      complete: () => {},
    });
  }

  handleBackNav = () => {
    history.back();
  };

  getAllDevices = () => {
    this.loading = true;
    this.managementRepo.GetAllDevice().subscribe({
      next: (v) => {
        this.Devices = v["data"];
        this.dtTrigger.next();
        this.loading = false;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
      },
    });
  };

  public calculateAge(birthdate: any): number {
    return moment().diff(birthdate, "years");
  }

  setUserData = (data: any, device: any) => {
    this.userData.name = data.name;
    this.userData.userName = device.userName;
    this.userData.email = data.email;
    this.userData.city = data.city;
    this.userData.country = data.country;
    this.userData.language = data.language;
    this.userData.phone = data.phone;
    this.userData.deviceName = device.deviceName;
    //HERE IS THE DIFERENT STATUS {assigned} OR {assigning}
    this.userData.deviceStatus = device.status  === "assigning" ? "New": device.status==="assigned"? "Re-assigned":"";
    this.userData.name = data.name;
    this.userData.salutation = data.salutation;

    this.userData.createdAt = device.createdAt;
  };

  getUserDetails = (device: any) => {
    this.managementRepo.GetUserbyid(device.userId).subscribe({
      next: (v) => {
        // console.log("device", device);
        this.setUserData(v["data"], device);
        // this.dtTrigger.next();
      },

      error: (e) => {
        // console.log(e);
      },
    });
  };

  deleteDevice(device: any) {

    this.dataerror = false;
    this.datasuccess = false;

    if (confirm("Are you sure you want to delete this device")) {
      this.managementRepo.deleteDeviceById(device.id).subscribe(
        (res) => {
          window.location.reload();
        },
        (err) => {
          alert("Error occured deleting user");
        }
      );
      this.loading = false;
    }
  }

  open(content: any, device: any) {
    this.getUserDetails(device);
    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      size: "xl",
    });
  }

  convertToDate(dateString) {
    const date = new Date(dateString);
    return date.toDateString();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
