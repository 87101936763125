<div class="chart-wrapper">
  <canvas baseChart
  [data]="[white, black, army, blue]"
  [colors]= "chartColors"
  [labels]="doughnutChartLabels"
  [chartType]="doughnutChartType">


</canvas>
<div class=" mt-5 mb-5">

  White = {{ white }}  ,  Black = {{ black }} , Army =  {{ army }}, Blue = {{ blue }}

</div>

</div>
