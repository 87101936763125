<div class="container-fluid pt-3">
  <a (click)="handleBackNav()" class="btn btn-secondary ml-3 mb-2"> Back </a>

  <h3 *ngIf="success" class="text-center">
    <strong>Log Actions </strong> for Device With Serial Number
    <strong>{{ DeviceLogs.serialNumber }}</strong>
  </h3>
  <h3 *ngIf="success" class="text-center">
    Start Time - {{ DeviceLogs.timeStamp | date }} <br />
    End Time - {{ DeviceLogs.endTime | date }}
  </h3>

  <div *ngIf="!success && !loading" class="card">
    <h5 class="text-danger p-5">No Action Record Found</h5>
  </div>
  <div class="table table-responsive p-3">
    <table
      datatable
      [dtOptions]="dtOptions"
      [dtTrigger]="dtTrigger"
      class="row-border hover"
    >
      <thead *ngIf="success">
        <tr>
          <th>Interaction</th>
          <th>TimeInSeconds</th>
          <th>End Time</th>
        </tr>
      </thead>
      <tbody *ngIf="success">
        <tr *ngFor="let item of action">
          <td>{{ item.interaction }}</td>
          <td>{{ item.timeInSeconds }}</td>
          <td>{{ item.endDatetime | date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<app-spinner *ngIf="loading" [loading]="true"></app-spinner>
