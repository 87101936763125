import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IApiResponseModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-users-country",
  templateUrl: "./users-country.component.html",
  styleUrls: ["./users-country.component.css"],
})
export class UsersCountryComponent implements OnInit {
  countryParams: string;
  Data: any = [];
  Users: IApiResponseModel[];
  dtOptions: any = {};
  loading: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();
  config: any;

  url: any = "";
  orderHeader: String = "createdAt";
  isDescOrder: boolean = true;
  caseInsensitive: boolean = true;
  searchWord: any = "";

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private route: ActivatedRoute,
    private Router: Router
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };

    this.route.queryParams.subscribe(
      (params) =>
        (this.config.currentPage = params["page"] ? params["page"] : 1)
    );

    this.url = window.location.pathname.split("/");
  }

  sort(headerName: String) {
    this.isDescOrder = !this.isDescOrder;
    this.orderHeader = headerName;
  }

  onPrintClick() {
    this.Router.navigate([`/print-users/` + this.url.split('/')[2]]);
  }

  convertToDate(dateString) {
    const date = new Date(dateString);
    return date.toDateString();
  }

  pageChange(newPage: number) {
    const title = window.location.pathname.split("/")[2];
    this.Router.navigate([`/country-data/${title}`], {
      queryParams: { page: newPage, size: 10 },
    });
  }

  handleBackNav = () => {
    history.back();
  };

  ngOnInit(): void {
    this.url = window.location.pathname;
    this.getDetails();
    this.GetLoginuser();
   
  }

  pageTitle: any = "";
  getDetails = () => {
    this.loading = true;
    this.managementRepo.GetDeviceCountry().subscribe({
      next: (response) => {
        this.loading = false;

        if (this.url.slice(-10) === "Netherland") {
          this.Data = response["data"].nl.data;
          this.pageTitle = "Users from Netherland";
        } else if (this.url.slice(-7) === "Belgium") {
          this.Data = response["data"].be.data;
          this.pageTitle = "Users from Belgium";
        } else if (this.url.slice(-14) === "United-Kingdom") {
          this.Data = response["data"].uk.data;
          this.pageTitle = "Users from United Kingdom";
        } else if (this.url.slice(-6) === "France") {
          this.Data = response["data"].fr.data;
          this.pageTitle = "Users from France";
        } else if (this.url.slice(-12) === "United-State") {
          this.Data = response["data"].us.data;
          this.pageTitle = "Users from United States";
        } else if (this.url.slice(-6) === "Others") {
          this.Data = response["data"].others.data;
          this.pageTitle = "Users from Other Countries";
        } else {
          this.Data = response["data"];
          this.pageTitle = "No Data Found";
        }

        this.dtTrigger.next();
      },

      error: (e) => {
        // console.log(e);
        this.loading = false;
      },
    });
  };

  deleteUser(user: any) {
    if (confirm("Are you sure you want to delete this user")) {
      this.managementRepo.deleteUserById(user.id).subscribe(
        (res) => {
          window.location.reload();
        },
        (err) => {
          alert("Error occured deleting user");
        }
      );
      this.loading = false;
    }
  }

  loggedInUser: any;
  userRole: any;
  GetLoginuser() {
    this.AuthService.GetUser().subscribe({
      next: (v) => {
        this.loggedInUser = v.data;
      },
      error: (e) => {
        // console.log("err", e);
      },
      complete: () => {},
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
