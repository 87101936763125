<h1 *ngIf="Users.length === 0">Loading...</h1>
<div class="row">
  <div class="col-md-2">
    <a (click)="handleBackNav()" class="btn btn-secondary ml-3 my-3"> Back </a>
  </div>
  <div class="col-md-8">
    <h3 *ngIf="Users.length > 0" class="text-center mt-2">{{ pageTitle }}</h3>
  </div>
</div>

<div ngxCopyPaste #cp="copy">
  <div>
    <button printSectionId="print-section" class="download-btn" ngxPrint>
      Print
    </button>
    <button (click)="exportExcel()" class="download-btn">Excel</button>
    <button (click)="cp.copy()" class="download-btn">Copy</button>
  </div>

  <div
    *ngIf="Users.length > 0"
    class="table-hover table-responsive p-3"
    id="print-section"
  >
    <table class="row-border hover table" id="excel-table">
      <thead>
        <tr class="print-headers">
          <th (click)="sort('fullname')">
            Full Name <i class="fa fa-sort"></i>
          </th>
          <th (click)="sort('email')">Email <i class="fa fa-sort"></i></th>
          <th (click)="sort('phone')">Phone <i class="fa fa-sort"></i></th>
          <th (click)="sort('role_id')">Role <i class="fa fa-sort"></i></th>
          <th (click)="sort('createdAt')">
            Date Registered <i class="fa fa-sort"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of Users">
          <td>{{ user?.fullname }}</td>
          <td>{{ user?.email }}</td>
          <td>{{ user?.phone ? user?.phone : "nill" }}</td>
          <td>
            {{
              user?.role_id === 1
                ? "SuperAdmin"
                : user?.role_id === 2
                ? "Admin"
                : "User"
            }}
          </td>
          <td>{{ convertToDate(user.createdAt) }}</td>
        </tr>
      </tbody>
    </table>
    <div id="content-loaded"></div>
  </div>
</div>
