import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";

@Component({
  selector: "app-bar-chart",
  templateUrl: "./bar-chart.component.html",
  styleUrls: ["./bar-chart.component.css"],
})
export class BarChartComponent {
  loading: boolean;
  Devicecolours: any;
  nl: any;
  be: any;
  uk: any;
  fr: any;
  us: any;
  others: any;

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private avRout: ActivatedRoute
  ) {
    this.getDeviceCountry();
  }
  ngOnInit(): void {}

  barChartOptions: ChartOptions = {
    responsive: true,
    onClick: function (e) {
      var element = this.getElementAtEvent(e);
      if (element.length && element[0]._view.label === "United Kingdom" || element.length && element[0]._view.label === "United State") {
        const text1 = element[0]._view.label.split(" ")[0];
        const text2 = element[0]._view.label.split(" ")[1];
        window.location.assign(
          window.location.origin + `/country-data/${text1}-${text2}`
        );
      } else {
        const text1 = element[0]._view.label.split(" ")[0];
        window.location.assign(
          window.location.origin + `/country-data/${text1}`
        );
      }
    },
  };

  public chartColors: any[] = [
    {
      backgroundColor: [
        "#E64F22",
        "#000000",
        "green",
        "grey",
        "orange",
        "blue",
      ],
    },
  ];
  barChartLabels: Label[] = [
    "Netherland",
    "Belgium",
    "United Kingdom",
    "France",
    "United State",
    "Others",
  ];
  barChartType: ChartType = "bar";
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: ChartDataSets[] = [{ label: "Top Users Country" }];

  getDeviceCountry = () => {
    this.loading = true;
    this.managementRepo.GetDeviceCountry().subscribe({
      next: (v) => {
        this.Devicecolours = v["data"];
        this.nl = v["data"].nl.count;
        this.be = v["data"].be.count;
        this.uk = v["data"].uk.count;
        this.fr = v["data"].fr.count;
        this.us = v["data"].us.count;
        this.others = v["data"].others.count;
        this.loading = false;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
      },
    });
  };
}
