import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as XLSX from "xlsx";

@Component({
  selector: "app-print-admin",
  templateUrl: "./print-admin.component.html",
  styleUrls: ["./print-admin.component.css"],
})
export class PrintAdminComponent implements OnInit {
  Users: any = [];
  filename = "admin-users.xlsx";
  url: any = [];
  loading: boolean = false;
  success: boolean = false;
  dataReady: boolean = false;
  userDevices: any;
  config: any;
  caseInsensitive: boolean = true;
  orderHeader: String = "createdAt";
  isDescOrder: boolean = true;

  constructor(
    private AuthService: IdentityService,
    private route: ActivatedRoute,
    private Router: Router
  ) {
    // this.route.queryParams.subscribe((params) => console.log("params", params));
  }

  pageTitle: any = "";
  ngOnInit(): void {
    this.url = window.location.pathname.split("/");
    this.getAdminUsers();
  }

  sort(headerName: String) {
    this.isDescOrder = !this.isDescOrder;
    this.orderHeader = headerName;
  }

  handleBackNav = () => {
    history.back();
  };

  getAdminUsers = () => {
    this.AuthService.getAllAdminUsers().subscribe({
      next: (response) => {
        this.Users = response;

        this.loading = false;
      },

      error: (e) => {
        // console.log(e);
        this.loading = false;
      },
    });
  };

  convertToDate(dateString) {
    const date = new Date(dateString);
    return date.toDateString();
  }

  exportExcel() {
    /* pass table ID here */
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.filename);
  }
}
