import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input() loading : boolean = false;
  constructor(private spinner: NgxSpinnerService) {

    this.loading?  this.spinner.show() : this.spinner.hide();

  }

  ngOnInit(): void {
    this.loading?  this.spinner.show() : this.spinner.hide();
  }

}
