<div class="container-fluid p-0 m-p side-menu-nav">
  <div id="main">
    <div class="topnav" id="myTopnav">
      <a
        *ngIf="isclose"
        href="javascript:void(0);"
        class="icon float-left"
        (click)="openNav()"
      >
        Menu <i class="fa fa-bars"></i>
      </a>
      <a [routerLink]="['/']"> Admin Dashboard</a>

      <a class="float-right" (click)="LogOut()"> Sign-out</a>

      <a
        *ngIf="user.role_id === 1"
        class="float-right add-btn"
        (click)="open(register)"
      >
        <i class="fa fa-plus"></i> Add New Admin
      </a>

      <a class="float-right welcometext">
        Administrator | <i class="fa fa-user-circle"></i> {{ UserName }}
      </a>

      <ng-template #register let-c="close" let-d="dismiss">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-add-admin></app-add-admin>
        </div>
      </ng-template>

      <a class="float-right btn" [routerLink]="['admin-users']">
        <i class="fa fa-users"></i> View Admin Users
      </a>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div id="mySidenav" class="sidenav">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()"
      >&times;</a
    >

    <div class="text-white h4 text-center">
      <img
        src="../../../assets/images/logo/logo.png"
        width="auto"
        height="40px"
        class="img bg-light img-responsive img-rounded"
      />
      <span> Gametimer</span>
    </div>
    <ul ngbNav [(activeId)]="active" orientation="vertical" class="nav-pills">
      <li [ngbNavItem]="1">
        <a ngbNavLink [routerLink]="['dashboard']" class="item">
          <i class="fa fa-home"> </i> <span class="side-item"> Dashboard </span>
        </a>
      </li>

      <li [ngbNavItem]="3">
        <a ngbNavLink [routerLink]="['all']" class="item">
          <i class="fa fa-users"> </i>
          <span class="side-item"> Registered Account</span>
        </a>
      </li>
<!-- 
      <li [ngbNavItem]="4">
        <a ngbNavLink [routerLink]="['/admin-users']" class="item">
          <i class="fa fa-users"> </i>
          <span class="side-item"> Admin Users </span>
        </a>
      </li> -->

      <li [ngbNavItem]="5">
        <a ngbNavLink [routerLink]="['device']" class="item">
          <i class="fa fa-tablet"> </i> <span class="side-item"> Devices</span>
        </a>
      </li>

      <li [ngbNavItem]="6">
        <a ngbNavLink [routerLink]="['devicelog']" class="item">
          <i class="fa fa-tablet"> </i>
          <span class="side-item"> Device Logs </span>
        </a>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink [routerLink]="['profile']" class="item">
          <i class="fa fa-user-circle"> </i>
          <span class="side-item"> My Profile </span></a
        >
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink [routerLink]="['/admin-users']" class="item">
          <i class="fa fa-users"> </i>
          <span class="side-item"> Admin Users </span>
        </a>
      </li>

      <li [ngbNavItem]="7">
        <a ngbNavLink (click)="LogOut()" class="item">
          <i class="fa fa-sign-out"> </i><span class="side-item"> Logout </span>
        </a>
      </li>
    </ul>
  </div>
</div>
<app-spinner *ngIf="loading" [loading]="true"></app-spinner>
