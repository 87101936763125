import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { IdentityService } from "src/app/Services/identity.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  requestLoading: boolean = false;
  requestSuccess: boolean = false;
  requestFailed: boolean = false;
  submitted: boolean = false;
  errorMessage: string = "";
  successMessage: string = "";
  redirectUrlString: any;

  constructor(
    private identityService: IdentityService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  routeParams: any;

  loading: boolean = false;
  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    this.form = this.formBuilder.group({
      newPassword: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
        ]),
      ],
      confirmPassword: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
        ]),
      ],
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.requestFailed = false;
    this.requestSuccess = false;
    this.submitted = false;
    this.errorMessage = "";

    // stop here if form is invalid
    if (this.form.invalid) {
      if (
        this.form.value.newPassword.length < 8 ||
        this.form.value.confirmPassword.length < 8
      ) {
        this.requestFailed = true;
        this.errorMessage = "Password must not be less than 8 characters";
      }
      return;
    }

    if (this.form.value.newPassword !== this.form.value.confirmPassword) {
      this.errorMessage = "Passwords do not match";
      this.requestFailed = true;
    } else {
      const data = {
        userId: this.routeParams.userId,
        resetString: this.routeParams.resetString,
        newPassword: this.form.value.newPassword,
      };

      this.sendRequest(data);
    }
  }

  sendRequest = (data) => {
    this.loading = true;
    this.identityService.updatePasswordReset(data).subscribe({
      next: (res) => {
        this.loading = false;
        this.requestSuccess = true;
        this.successMessage = res["message"];
        this.errorMessage = "";
        this.form.value.newPassword = "";
        this.form.value.confirmPassword = "";
      },

      error: (error) => {
        this.loading = false;
        this.requestSuccess = false;
        this.requestFailed = true;
        this.errorMessage = error.message;
        this.successMessage = "";
      },
    });
  };
}
