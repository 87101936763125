<app-spinner *ngIf="registerloading" [loading]="true"></app-spinner>
<div class="container mt-2">
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-12">
      <div class="card">
        <h4 class="card-header text-center">Create Admin Account</h4>
        <div class="card-body">
          <div
            *ngIf="registerfailed"
            class="alert alert-success fade show"
            role="alert"
          >
            * {{ SuccessMessage }}
          </div>
          <div
            *ngIf="registerfailed"
            class="alert alert-danger fade show"
            role="alert"
          >
            * {{ errormessage }}
          </div>

          <div
            *ngIf="duplicateuseremail"
            class="alert alert-danger fade show"
            role="alert"
          >
            * Email Already Exist
          </div>

          <div
            *ngIf="duplicateusername"
            class="alert alert-danger fade show"
            role="alert"
          >
            * User Name Already Exist
          </div>

          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="email">User Name</label>
              <input
                type="text"
                formControlName="fullname"
                class="form-control input"
                [ngClass]="{ 'is-invalid': submitted && f.fullname.errors }"
              />
              <div
                *ngIf="submitted && f.fullname.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.fullname.errors.required">Name is required</div>

                <div *ngIf="f.fullname.errors.maxlength">Name is too Long</div>
              </div>
            </div>

            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                formControlName="email"
                class="form-control input"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">
                  Enter a Valid Email Address
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="password">Password</label>
              <input
                type="password"
                formControlName="password"
                class="form-control input"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
              />
              <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.password.errors.required">
                  Password is required
                </div>
                <div *ngIf="f.password.errors.minlength">
                  Password is too Weak
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="password">Select Role</label>

              <select
                formControlName="role_id"
                class="form-control input"
                [ngClass]="{ 'is-invalid': submitted && f.role_id.errors }"
              >
                <option value="" selected>Select Role</option>
                <option *ngFor="let Role of Roles" value="{{ Role.id }}">
                  {{
                    Role.role_name === "Director"
                      ? "Super Admin"
                      : Role.role_name
                  }}
                </option>
              </select>

              <div
                *ngIf="submitted && f.role_id.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.role_id.errors.required">Pls Select Role</div>
              </div>
            </div>

            <div class="form-group">
              <button
                [disabled]="registerloading"
                class="btn loginbtn input btn-block"
              >
                <span
                  *ngIf="registerloading"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                Add Admin
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-md-3"></div>
  </div>
</div>
