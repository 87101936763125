import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { IApiResponseModel } from "src/app/interface/interface";
import { IdentityService } from "src/app/Services/identity.service";
import { ManagementService } from "src/app/Services/management.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastComponent } from "../toast/toast.component";

@Component({
  selector: "app-device-byuser",
  templateUrl: "./device-byuser.component.html",
  styleUrls: ["./device-byuser.component.css"],
})
export class DeviceByuserComponent implements OnInit {
  searchWord: any;
  toggleBox: boolean = false;
  success: boolean = false;
  closeResult: string;
  config: any;
  datasuccess: boolean = false;
  dataerror: boolean = false;
  errormessage: string = "";
  successmessage: string = "";
  AdminUser: any;
  userid: any;
  User: any = {};
  userData: any = {
    name: "",
    userName: "",
    email: "",
    city: "",
    country: "",
    language: "",
    salutation: "",
    phone: "",
    deviceName: "",
    deviceStatus: "",
    createdAt: "",
  };
  Device: any = [];
  dtOptions: any = {};
  loading: boolean = false;
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private AuthService: IdentityService,
    private managementRepo: ManagementService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private Router: Router
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };

    this.route.queryParams.subscribe(
      (params) =>
        (this.config.currentPage = params["page"] ? params["page"] : 1)
    );
  }

  pageChange(newPage: number) {
    this.Router.navigate(["device/4"], {
      queryParams: { page: newPage, size: 10 },
    });
  }

  ngOnInit(): void {
    this.GetLoginuser();
    const paramid = "userid";
    if (this.route.snapshot.params[paramid]) {
      this.userid = this.route.snapshot.params[paramid];

      this.getUser(this.userid);
      this.getAllDevices(this.userid);
      this.dtOptions = {
        pagingType: "full_numbers",
        pageLength: 10,
        // Declare the use of the extension in the dom parameter
        dom: "Bfrtip",
        initComplete: function (settings, json) {
          $(".buttons").addClass("btn-success");
        },
        // Configure the buttons
        buttons: ["copy", "print", "excel"],
      };
    }
  }

  loggedInUser: any;
  userRole: any;
  GetLoginuser() {
    this.AuthService.GetUser().subscribe({
      next: (v) => {
        this.loggedInUser = v.data;
      },
      error: (e) => {
        // console.log("err", e);
      },
      complete: () => {},
    });
  }

  handleBackNav = () => {
    history.back();
  };

  handleToggleBox = () => {
    this.toggleBox = !this.toggleBox;
  };

  getUser = (id) => {
    this.loading = true;
    this.managementRepo.GetUserbyid(id).subscribe({
      next: (v) => {
        this.User = v["data"];
        this.loading = false;
      },

      error: (e) => {
        this.loading = false;
      },
    });
  };

  deleteDevice(device: any) {
    this.dataerror = false;
    this.datasuccess = false;

    if (confirm("Are you sure you want to delete this device")) {
      this.managementRepo.deleteDeviceById(device.id).subscribe(
        (res) => {
          window.location.reload();
        },
        (err) => {
          // console.log("error", err);
          alert("Error occured deleting user");
        }
      );
      this.loading = false;
    }
  }

  getAllDevices = (id: any) => {
    this.loading = true;
    this.managementRepo.getdevicebyuser(id).subscribe({
      next: (v) => {
        this.Device = v["data"];

        this.dtTrigger.next();
        this.loading = false;
        this.success = true;
      },

      error: (e) => {
        // console.log(e), (this.loading = false);
        this.success = false;
      },
    });
  };

  setUserData = (data: any, device: any) => {
    this.userData.name = data.name;
    this.userData.userName = device.userName;
    this.userData.email = data.email;
    this.userData.city = data.city;
    this.userData.country = data.country;
    this.userData.language = data.language;
    this.userData.phone = data.phone;
    this.userData.deviceName = device.deviceName;
    this.userData.deviceStatus = device.status;
    this.userData.name = data.name;
    this.userData.salutation = data.salutation;
    //HERE IS THE DIFERENT STATUS {assigned} OR {assigning}
    this.userData.deviceStatus =
      device.status === "assigning"
        ? "New"
        : device.status === "assigned"
        ? "Re-assigned"
        : "";
    this.userData.createdAt = device.createdAt;
  };

  getUserDetails = (device: any) => {
    this.managementRepo.GetUserbyid(device.userId).subscribe({
      next: (v) => {
        this.setUserData(v["data"], device);
        this.dtTrigger.next();
      },

      error: (e) => {
        // console.log(e);
      },
    });
  };

  open(content: any, device: any) {
    this.getUserDetails(device);
    this.modalService.open(content, {
      centered: true,
      backdrop: "static",
      size: "xl",
    });
  }

  convertToDate(dateString) {
    const date = new Date(dateString);
    return date.toDateString();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
